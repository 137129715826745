import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SiteContext } from "../context";
import { Pagination } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object().shape({
  author: Yup.string()
    .label("Name")
    .required()
    .min(5, "Must have at least 3 characters"),
  email: Yup.string()
    .email("Enter a valid email")
    .label("Your email")
    .required(),
});

const Comments = ({ slug, section }) => {
  let navigate = useNavigate();

  const { apiUrl, userData, formatAmount } = useContext(SiteContext);

  const [items, setItems] = useState([]);
  const [story, setStory] = useState("");
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [commloading, setCommLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [key, setKey] = useState("Comments");

  useEffect(() => {
    getComments();
  }, [slug, page, limit]);

  const getComments = () => {
    return fetch(
      `${apiUrl}/api/comments.php?action=getcomments&slug=${slug}&section=${section}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          setItems(res.rows);
          setTotal(res.count);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleSubmit = (
    values,
    { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }
  ) => {
    setCommLoading(true);

    if (story === "" || story === undefined) {
      alert("Please add your comment.");
      setSubmitting(false);
      setCommLoading(false);
      return;
    }

    let formData = new FormData();

    formData.append("author", values.author);
    formData.append("email", values.email);
    formData.append("comment", story);
    formData.append("slug", slug);
    formData.append("section", section);
    formData.append("username", userData?.username);
    formData.append("showemail", values.showemail ? 1 : 0);
    formData.append("action", "post_comment");

    return fetch(`${apiUrl}/api/comments.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setStory("");
          setMsg(res.msg);
          setCommLoading(false);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setCommLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Comment submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  return (
    <div className="py-5 my-4">
      <h3>Comments</h3>

      <div className="my-2 d-flex justify-content-between">
        <div className="align-items-center">
          {msg !== "" ? (
            <div className="p-2">
              <span className="p-2 border border-info text-dark">{msg}</span>
            </div>
          ) : null}
          {errorMsg !== "" ? (
            <div className="p-2">
              <span className="p-2 border border-info text-dark">
                {errorMsg}
              </span>
            </div>
          ) : null}
        </div>
      </div>

      <Tabs defaultActiveKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="Comments" title="Comments">
          {items.length > 0 ? (
            <div className="mb-2 pb-2">
              <h6 className="px-3 small my-3">
                {total} Comment{parseInt(total) === 1 ? "" : "s"}
              </h6>

              {items.map((item) => {
                return (
                  <div className="py-2 my-3 row" key={item.id_ad}>
                    <div className="col-2 col-md-1 p-2 text-end">
                      <i className="bi bi-person h3"></i>
                    </div>
                    <div className="col-10 col-md-11 border rounded px-0">
                      <div className="bg-light border p-3">
                        <span className="h6">{item.comm_name}</span>{" "}
                        {parseInt(item.comm_showemail) === 1 &&
                          `(${item.comm_email})`}{" "}
                        commented on{" "}
                        {moment(item.comm_timeposted).format("Do, MMM YYYY")}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.comment }}
                        className="p-3"
                      />
                    </div>
                  </div>
                );
              })}

              {items.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}
            </div>
          ) : (
            <p>No comments posted yet.</p>
          )}
        </Tab>
        <Tab eventKey="Post comment" title="Post comment">
          <Formik
            initialValues={{
              author: userData ? userData.name : "",
              email: userData ? userData.email : "",
              showemail: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="row my-2">
                    <div className="col-md-8">
                      {!userData && (
                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="author">Name</label>
                          </div>
                          <Field
                            id="author"
                            name="author"
                            value={values.author}
                            onChangeText={handleChange("author")}
                            placeholder="Name"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("author")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.author && errors.author && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.author && errors.author}
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      {!userData && (
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="email">Email address</label>
                          </div>
                          <Field
                            id="email"
                            name="email"
                            value={values.email}
                            onChangeText={handleChange("email")}
                            placeholder="Email address"
                            placeholderTextColor="#999"
                            onBlur={handleBlur("email")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.email && errors.email && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.email && errors.email}
                              </span>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="form-group my-1">
                        <div className="py-1">
                          <label htmlFor="comment">
                            {userData
                              ? `Posting comment as ${userData.name}`
                              : "Comment"}
                          </label>
                        </div>
                        <ReactQuill
                          theme="snow"
                          value={story}
                          onChange={setStory}
                        />
                      </div>

                      <div className="form-group my-1 py-2">
                        <div>
                          <label htmlFor="showemail">Display email?</label>
                          <Field
                            type="checkbox"
                            name="showemail"
                            className="mx-2"
                          />{" "}
                          {values.showemail ? "Yes" : "No"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    buttonType="outline"
                    onClick={handleSubmit}
                    title="Submit"
                    disabled={!isValid || isSubmitting || commloading}
                    loading={isSubmitting}
                    className="btn btn-primary"
                  >
                    Submit
                  </button>

                  <button
                    onClick={() => navigate(-1)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </Tab>
      </Tabs>
    </div>
  );
};

export default Comments;
