import React, { useState, useEffect } from "react";

const TimeLeft = ({ dd, color }) => {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    let draw_date = new Date(dd).getTime();

    let x = setInterval(() => {
      let now = new Date().getTime();

      let distance = draw_date - now;

      let d = Math.floor(distance / (1000 * 60 * 60 * 24));
      let h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let s = Math.floor((distance % (1000 * 60)) / 1000);

      setDays(d);
      setHours(h);
      setMins(m);
      setSec(s);

      if (distance <= 0) {
        clearInterval(x);
        setDays("00");
        setHours("00");
        setMins("00");
        setSec("00");
      }
    }, 1000);
  }, []);

  const formatDate = (n) => {
    if (n.toString().length < 2) {
      return "0" + n;
    }
    return n;
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="row text-white my-1 py-2">
        <div className={`col-3 text-center ${color}`}>
          <span
            style={{ backgroundColor: "#ff0000" }}
            className="p-2 text-white rounded h3 py-2"
          >
            {formatDate(days)}
          </span>
          <h6 className="my-2 py-2">days</h6>
        </div>
        <div className={`col-3 text-center ${color}`}>
          <span
            style={{ backgroundColor: "#ff0000" }}
            className="p-2 text-white rounded h3 py-2"
          >
            {formatDate(hours)}
          </span>
          <h6 className="my-2 py-2">hours</h6>
        </div>
        <div className={`col-3 text-center ${color}`}>
          <span
            style={{ backgroundColor: "#ff0000" }}
            className="p-2 text-white rounded h3 py-2"
          >
            {formatDate(mins)}
          </span>
          <h6 className="my-2 py-2">mins</h6>
        </div>
        <div className={`col-3 text-center ${color}`}>
          <span
            style={{ backgroundColor: "#ff0000" }}
            className="p-2 text-white rounded h3 py-2"
          >
            {formatDate(sec)}
          </span>
          <h6 className="my-2 py-2">sec</h6>
        </div>
      </div>
    </div>
  );
};

export default TimeLeft;
