import React from 'react';
import { Header, Footer } from './';
import { Outlet } from 'react-router-dom';
import { AppLayout } from "../components";

const BlogLists = () => {


  return (
    <div>

      <div className='container'>
        <Outlet />
      </div>

    </div>
  )
}

export default AppLayout(BlogLists);