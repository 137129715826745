import React, { useState } from "react";
const SearchBox = ({ handleSubmit }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const checkSubmit = (e) => {
    e && e.preventDefault();
    searchTerm !== "" && handleSubmit(searchTerm);
    setSearchTerm("");
  };

  return (
    <div className="flex rounded-full bg-white justify-start items-center p-1">
      <form onSubmit={checkSubmit}>
        <input
          type="text"
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex pl-2 w-full text-black outline-none"
        />
      </form>
      <button
        type="button"
        onClick={() => checkSubmit()}
        className="bg-emerald-600 hover:bg-emerald-800 text-white rounded-full py-1 px-2"
      >
        Search
      </button>
    </div>
  );
};

export default SearchBox;
