import React, { useState, useEffect, useContext } from "react";
import {
  Pagination,
  NavigationBar,
  BannerSide,
  AppLayout,
} from "../components";
import moment from "moment";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import { Seo, Header, Footer, BlogCatYear } from "./";

const BlogsByYear = () => {
  const { id } = useParams();

  let navigate = useNavigate();

  const { apiUrl, userData, isMobile, siteconfig } = useContext(SiteContext);

  const [articles, setArticles] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(30);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getArticlesByYear();
  }, [id, page, limit]);

  const getArticlesByYear = () => {
    setLoading(true);

    fetch(
      `${apiUrl}/api/articles.php?action=fetchblogyear&year=${id}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setArticles(res.rows);
            setTotal(res.rows.length);
            setLoading(false);
          }
        } else if (res.status === 400) {
          setLoading(false);
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  return (
    <div>
      <Seo
        title={`Blog | ${siteconfig.shopname}`}
        description="Articles"
        url={`${apiUrl}/blog`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <div className="container my-2">
        <div className="my-2 d-flex justify-content-between">
          <div className="align-items-center">
            {msg !== "" ? (
              <div className="p-2">
                <span className="p-2 text-dark">{msg}</span>
              </div>
            ) : null}
            {errorMsg !== "" ? (
              <div className="p-2">
                <span className="p-2 text-dark">{errorMsg}</span>
              </div>
            ) : null}
          </div>
        </div>

        {articles.length > 0 && (
          <div className="row">
            <div className="col-md-9 col-lg-9">
              <div className="row">
                {articles.map((article, i) => {
                  return (
                    <div className="col-md-6 p-2">
                      <Link
                        className="text-decoration-none text-dark text-left"
                        to={`/post/${article.titleslug}`}
                      >
                        <div className="d-flex justify-content-start align-content-start border-bottom">
                          <div>
                            <img
                              src={`${apiUrl}/${article.artphoto}`}
                              alt={article.title}
                              className="w-full pr-2 h-[100px] md:w-[150px] md:h-[130px] object-contain"
                            />
                          </div>

                          <div className="p-1">
                            <h6 className="my-1 py-1">{article.title}</h6>
                            <span className="bg-danger text-white p-1 small rounded">
                              {article.artcategory}
                            </span>
                            <div className="my-1 py-1">
                              <span className="small text-left">
                                {article.author} |{" "}
                                <i className="bi bi-clock"></i>:{" "}
                                {moment(article.artpubdate).format(
                                  "Do MMM YYYY h:mma"
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>

              {articles.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}
            </div>
            <div className="col-md-3 col-lg-3">
              <BlogCatYear />
              <BannerSide />
            </div>
          </div>
        )}
        {loading && (
          <div className="d-flex justify-content-center align-content-center my-5">
            <div class="spinner-border text-secondary" role="status" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLayout(BlogsByYear);
