import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from ".";
import { Pagination, AppLayout } from "../components";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { DashboardMenu } from "../components";
import { SiteContext } from "../context";
import ReactModal from "react-modal";

const AdminEvents = () => {
  let navigate = useNavigate();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [events, setEvents] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getEvents();
  }, [page, limit]);

  const getEvents = () => {
    setLoading(true);

    fetch(
      `${apiUrl}/api/events.php?action=fetch&role=${userData.role}&page=${page}&limit=${limit}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.rows.length > 0) {
            setEvents(res.rows);
            setTotal(res.rows.length);
          }
          setLoading(false);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setLoading(false);

          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  const handleAdd = () => {
    navigate("/addevent");
  };

  const handleUpdate = (event) => {
    navigate("/updateevent", {
      state: { event: event, action: "update" },
    });
  };

  const handleDelete = (eventid) => {
    setItemSelected(eventid);
    setShowModal(true);
  };

  const doDelete = (itemSelected) => {
    let formData = new FormData();

    formData.append("event", itemSelected);
    formData.append("role", userData.role);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/events.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          getEvents();
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span
            className="btn btn-primary"
            onClick={() => doDelete(itemSelected)}
          >
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>Events</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add event
                  </button>
                </div>
              </div>

              {loading && (
                <div className="d-flex justify-content-center align-content-center my-5">
                  <div class="spinner-border text-secondary" role="status" />
                </div>
              )}

              {events.length > 0 && (
                <Pagination
                  limit={limit}
                  page={page}
                  total={total}
                  cangoBack={cangoBack}
                  cangoForward={cangoForward}
                  handleChange={handleChange}
                  doNext={doNext}
                  doPrevious={doPrevious}
                />
              )}

              {events.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-7">Item</div>
                    <div className="col-2">Status</div>
                    <div className="col-2">Action</div>
                  </div>
                  {events.map((eventitem, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-7">
                          <Link
                            className="text-decoration-none text-dark"
                            to={`/events/${eventitem.eventid}/${eventitem.eventtitle_slug}`}
                          >
                            {eventitem.eventtitle}
                          </Link>{" "}
                          <br />
                          From: {eventitem.e_time_start},{" "}
                          {moment(eventitem.e_date_start).format(
                            "Do MMM, YYYY"
                          )}
                          <br />
                          To: {eventitem.e_time_end},{" "}
                          {moment(eventitem.e_date_end).format("Do MMM, YYYY")}
                        </div>
                        <div className="col-2">{eventitem.e_status}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(eventitem)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>{" "}
                          <a onClick={() => handleDelete(eventitem.eventid)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminEvents);
