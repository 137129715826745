import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { SiteContext } from "../context";

const DashboardMenu = () => {
  const { isLoggedOn, userData } = useContext(SiteContext);
  const userDataStorage = JSON.parse(localStorage.getItem("user"));

  const userDataInfo = userData || userDataStorage;

  const [showModal, setShowModal] = useState(false);

  const AdminMenu = () => {
    return (
      <div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminpages">
            Site Pages
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminnews">
            News
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/articles">
            Articles
          </Link>
        </div>{" "}
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminevents">
            Events
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminleagueteams">
            League teams
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminbannerads">
            Banners
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/elections">
            Elections
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminelections">
            Elections Manage
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/contributionsuser">
            {`Dues & Levies`}
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/orgexpensesuser">
            Expenses
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/users">
            Users
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/admincomments">
            Comments
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminphotogallery">
            Photo Gallery
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminvideogallery">
            Video Gallery
          </Link>
        </div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/adminsiteconfig">
            Site Config
          </Link>
        </div>
      </div>
    );
  };

  const MemberMenu = () => {
    return (
      <div>
        <div className="py-1 border-bottom">
          <Link className="my-1 text-decoration-none" to="/articles">
            Articles
          </Link>
        </div>
        <div className="py-1 border-bottom">
              <Link className="my-1 text-decoration-none" to="/elections">
                Elections
              </Link>
            </div>

      </div>
    );
  };

  return (
    <div>
      <div className="p-3">
        {isLoggedOn ? (
          <div className="text-dark">
            <h5>Navigation {userDataInfo.role}</h5>
            <div className="py-1 border-bottom">
              <Link className="my-1 text-decoration-none" to="/user">
                Dashboard Home
              </Link>
            </div>
            {userDataInfo && userDataInfo.role === "admin" ? (
              <div className="my-2">
                <AdminMenu />
              </div>
            ) : userDataInfo &&
              (userDataInfo.member_type === "full" ||
                userDataInfo.role === "admin") ? (
              <div className="my-2">
                <MemberMenu />
              </div>
            ) : null}
            <div className="py-1 border-bottom">
              <Link className="my-1 text-decoration-none" to="/profile">
                Profile
              </Link>
            </div>
            <div className="py-1 border-bottom">
              <Link
                className="my-1 text-decoration-none"
                to="/contributiondashboard"
              >
                Dues Table
              </Link>
            </div>

            <div className="py-1 border-bottom">
              <Link className="my-1 text-decoration-none" to="/changepassword">
                Change Password
              </Link>
            </div>
          </div>
        ) : (
          <div className="py-5 px-3 my-5">Not logged on</div>
        )}
      </div>
    </div>
  );
};

export default DashboardMenu;
