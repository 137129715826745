import React from "react";
import ReactModal from "react-modal";
import { SignupForm } from "../components";

const AddUserModal = ({
  handleSubmit,
  showModal,
  setShowModal,
  errorMsg,
  isMobile,
}) => {
  return (
    <ReactModal
      isOpen={showModal}
      ariaHideApp={false}
      style={{
        overlay: {
          flex: 1,
          justifyContent: "center",
          alignContent: "center",
          position: "fixed",
          top: isMobile ? "2%" : "10%",
          bottom: isMobile ? "2%" : "10%",
          left: isMobile ? 0 : "10%",
          right: isMobile ? 0 : "10%",
          width: isMobile ? "100%" : "80%",
          height: isMobile ? "96%" : "80%",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
        },
        content: {
          position: "absolute",
          top: "10px",
          left: "10px",
          right: "10px",
          bottom: "10px",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      <div>
        <div className="d-flex justify-content-end">
          <span
            className="btn btn-secondary my-2"
            onClick={() => setShowModal(false)}
          >
            <i className="bi bi-x-square h4 text-white"></i>
          </span>
        </div>
        {errorMsg !== "" ? (
          <div className="mt-2">
            <span className="p-2 my-2 border border-danger text-danger">
              {errorMsg}
            </span>
          </div>
        ) : null}
        <SignupForm handleSubmit={handleSubmit} setShowModal={setShowModal} />
      </div>
    </ReactModal>
  );
};

export default AddUserModal;
