import React, { useState, useContext } from "react";
import { UpdateProfilePictureModal } from "../modals";
import { SiteContext } from "../context";

const UpdateProfilePicture = ({ user }) => {
  const { apiUrl, userData, setUserData } = useContext(SiteContext);

  const [updateItem, setUpdateItem] = useState({});
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    let formData = new FormData();

    formData.append("photo", values.photo);
    formData.append("prole", values.posterrole);
    formData.append("pid", values.posterid);
    formData.append("user", values.id);
    formData.append("action", "updatepicture");

    const url = `${apiUrl}/api/users.php`;
    const sendType = "post";

    return fetch(url, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(response.msg);
          setUserData(response.user);
          setTimeout(() => {
            setMsg("");
          }, 4000);
        } else if (response.status === 400) {
          setErrorMsg(response.msg);
          setSubmitting(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        } else {
          alert(
            "Signup failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleUpdate = (user) => {
    setUpdateItem(user);
    setShowUpdateModal(true);
  };

  return (
    <div>
      <UpdateProfilePictureModal
        user={user}
        updateItem={updateItem}
        handleSubmit={handleSubmit}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        errorMsg={errorMsg}
      />
      <div className="my-3">
        {msg !== "" ? (
          <div className="my-4">
            <span className="p-2 my-2 border border-info text-dark">{msg}</span>
          </div>
        ) : null}
        {errorMsg !== "" ? (
          <div className="my-4">
            <span className="p-2 my-2 border border-info text-dark">
              {errorMsg}
            </span>
          </div>
        ) : null}
        <img
          src={`${apiUrl}/${user.photo}`}
          height={100}
          width={90}
          alt="picture"
        />
      </div>
    </div>
  );
};

export default UpdateProfilePicture;
