import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { Pagination, AppLayout } from "../components";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { DashboardMenu } from "../components";
import { SiteContext } from "../context";
import ReactModal from "react-modal";

const AdminPhotoGalleryCat = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [photoCat, setPhotoCat] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getPhotosCat();
  }, []);

  const getPhotosCat = () => {
    fetch(`${apiUrl}/api/photogallery.php?action=fetchcat`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.categories.length > 0) {
            setPhotoCat(res.categories);
          }
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAdd = () => {
    navigate("/addphotogallerycat");
  };

  const handleUpdate = (item) => {
    navigate("/updatephotogallerycat", { state: { photo: item } });
  };

  const handleDelete = (photoid) => {
    setItemSelected(photoid);
    setShowModal(true);
  };

  const doDelete = (itemSelected) => {
    let formData = new FormData();

    formData.append("photocatid", itemSelected);
    formData.append("role", userData.role);
    formData.append("action", "deletecat");

    fetch(`${apiUrl}/api/photogallery.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          getPhotosCat();
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span
            className="btn btn-primary"
            onClick={() => doDelete(itemSelected)}
          >
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Photo Gallery Categories -{" "}
                <Link to="/adminphotogallery">Photos</Link>
              </h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New Category
                  </button>
                </div>
              </div>

              {photoCat && (
                <div className="container p-3">
                  <div className="row h5 p-2 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-9">Category</div>
                    <div className="col-2">Action</div>
                  </div>
                  {photoCat.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-9">{item.gallcatname}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>{" "}
                          <a onClick={() => handleDelete(item.gallcatid)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminPhotoGalleryCat);
