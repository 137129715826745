import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  ImageDisplay,
  MotionWrap,
  classNames,
  BannerSide,
} from "../components";
import { SiteContext } from "../context";

const BlogHome = () => {
  const { homeBlog, apiUrl, isMobile } = useContext(SiteContext);
  return (
    <div className="w-full" style={{ maxWidth: "1300px" }}>
      {homeBlog?.length > 0 && (
        <div className="p-3 my-1">
          <div className="bg-red-700 text-white uppercase rounded font-bold text-2xl p-2">
            Blog
          </div>
          <div className="row">
            {homeBlog?.slice(0, 4).map((item, i) => {
              return (
                <div className="col-md-3 col-6 h-[400px] p-2">
                  <Link
                    key={i}
                    to={`/post/${item.titleslug}`}
                    className="text-decoration-none text-dark"
                  >
                    <div className="flex flex-col justify-center items-center bg-red-600 text-center rounded">
                      <div className="w-full h-[250px] md:h-[280px]">
                        <img
                          src={`${apiUrl}/${item.artphoto}`}
                          alt={item.title}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          className="rounded"
                        />
                      </div>
                      <div className="p-2 md:text-xl text-white text-left">
                        {item.title}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MotionWrap(BlogHome, classNames);
