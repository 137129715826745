import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import moment from "moment";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { removeTags, getHeading } from "../utils";
import { SiteContext } from "../context";
import { Header, Footer, Seo } from "./";

const Elections = () => {
  const navigate = useNavigate();

  const { apiUrl, pages, siteconfig, isMobile, handleCurrentPage } =
    useContext(SiteContext);

  const [elections, setElections] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [filteredPositions, setFilteredPositions] = useState([]);
  const [selectedElection, setSelectedElection] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getElections();
    window.scrollTo(0, 0);
  }, []);

  const getElections = () => {
    fetch(`${apiUrl}/api/electioncandidates.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let uniqueArr = [];
          let filtereduniqueArr = res.rows.filter((item) => {
            if (!uniqueArr.includes(item.elcandposition)) {
              uniqueArr.push(item.elcandposition);
            }
          });
          setElections(res.elections);
          setCandidates(res.rows);
          setFilteredPositions(uniqueArr);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleManifesto = (id) => {
    setItemSelected(id);
    setShowModal(true);
  };

  const handleElection = (type) => {
    let filteredElection = elections.filter((item) => item.electionid === type);
    let filtered = candidates.filter((item) => item.electionid === type);
    setFilteredCandidates(filtered);
    setSelectedElection(filteredElection[0]);
  };

  const getVoting = () => {
    let thedate = `${selectedElection?.eltimeend} ${selectedElection?.time_endH}:${selectedElection?.time_endM}`;
    if (new Date() < new Date(thedate)) {
      return true;
    } else {
      return false;
    }
  };

  const getVotingBegin = () => {
    let thedate = `${selectedElection?.eltimestart} ${selectedElection?.time_startH}:${selectedElection?.time_startM}`;
    if (new Date() < new Date(thedate)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "15%",
            right: "15%",
            width: "70%",
            height: "auto",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="text-right">
            <span
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              X
            </span>
          </div>
          <p className="h3 my-1 py-1">{itemSelected.elcandidate}'s manifesto</p>
          <p>{itemSelected.elcandmanifesto}</p>
        </div>
      </ReactModal>

      <Seo
        title={"Elections | " + siteconfig.shopname}
        description={"EXCO Elections"}
        url={`${apiUrl}/elections}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="container my-2 p-3">
        <div className="row">
          <div className="col-md-8">
            {selectedElection?.hasOwnProperty("elname") ? (
              <div>
                <h3 className="py-2 my-2">{selectedElection?.elname}</h3>
                <h6>
                  {`From: ${selectedElection?.time_startH}:${
                    selectedElection?.time_startM
                  }, ${moment(selectedElection?.eltimestart).format(
                    "Do MMM, YYYY"
                  )} `}
                  to
                  {` ${selectedElection?.time_endH}:${
                    selectedElection?.time_endM
                  }, ${moment(selectedElection?.eltimeend).format(
                    "Do MMM, YYYY"
                  )}`}
                  <p className="bg-gray-800 text-white my-1 text-center rounded p-2">
                    {getVotingBegin()
                      ? "Yet to commence"
                      : getVoting()
                      ? "Ongoing"
                      : "Concluded"}
                  </p>
                </h6>
              </div>
            ) : (
              <p>
                This is the election section. Click on the election you are
                interested in.
              </p>
            )}
            {filteredPositions.length > 0 &&
              filteredPositions.map((item) => {
                const position = item;
                let filtered = filteredCandidates.filter(
                  (item) => item.elcandposition === position
                );

                return (
                  filtered.length > 0 && (
                    <div className="p-3 my-2 rounded">
                      <div className="flex justify-between content-center bg-gray-300 h3 p-2">
                        <div>{position.toUpperCase()}</div>
                       {!getVotingBegin() &&  <Link
                          to={`/election/${
                            selectedElection.electionid
                          }/${position.toLowerCase().replace(" ", "-")}`}
                          className="h4 bg-green-100 px-2 py-1 rounded text-decoration-none cursor-pointer"
                        >
                          Vote
                        </Link>}
                      </div>
                      {filtered.map((item, i) => {
                        return (
                          <div
                            key={i}
                            className="flex bg-gray-100 my-2 p-3 rounded"
                          >
                            <img
                              className="h-12 w-12 rounded-full"
                              src={`${apiUrl}/${item.elcandphoto}`}
                              alt="photo"
                            />
                            <div className="ml-3">
                              <div className="h4">{item.elcandidate}</div>
                              <div
                                onClick={() => handleManifesto(item)}
                                className="text-sm cursor-pointer bg-gray-700 text-white py-1 px-2 rounded"
                              >
                                See manifesto
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                );
              })}
          </div>
          <div className="col-md-4 mt-2">
            <h4 className="bg-green-700 text-white rounded border-bottom p-2">
              Elections
            </h4>
            {elections.length > 0 &&
              elections.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="text-decoration-none border-bottom my-1"
                  >
                    <button
                      className="text-dark text-decoration-none"
                      onClick={() => handleElection(item.electionid)}
                    >
                      <div className="p-2">{item.elname}</div>
                    </button>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(Elections);
