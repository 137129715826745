import React, { useState, useContext } from "react";
import { SiteContext } from "../context";
import { Link, useNavigate } from "react-router-dom";
import ReactModal from "react-modal";

const TableUser = ({
  users,
  onDelete,
  filterUsers,
  filteredUsers,
  handleUpdate,
}) => {
  const { apiUrl, isMobile } = useContext(SiteContext);
  const [showModal, setShowModal] = useState(false);
  const [catSelected, setCatSelected] = useState("all");
  const [itemSelected, setItemSelected] = useState("");

  let navigate = useNavigate();

  const handleUser = (id) => {
    navigate("/userprofile", { state: id });
  };

  const handleDelete = (id) => {
    setItemSelected(id);
    setShowModal(true);
  };

  const handleFiltered = (cat) => {
    setCatSelected(cat);
    filterUsers(cat);
  };

  const doDelete = (id) => {
    onDelete(id);
    setItemSelected("");
    setShowModal(false);
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "25%" : "20%",
            bottom: isMobile ? "25%" : "30%",
            left: isMobile ? "2%" : "25%",
            right: isMobile ? "2%" : "25%",
            width: isMobile ? "96%" : "50%",
            height: isMobile ? "50%" : "50%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span
            className="btn btn-primary"
            onClick={() => doDelete(itemSelected)}
          >
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <div className="container p-3">
        <div className="my-2 py-1">
          <button
            onClick={() => handleFiltered("all")}
            className={
              catSelected === "all"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            All
          </button>
          <button
            onClick={() => handleFiltered("exco")}
            className={
              catSelected === "exco"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            EXCO
          </button>
          <button
            onClick={() => handleFiltered("bot")}
            className={
              catSelected === "bot"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            BOT
          </button>
          <button
            onClick={() => handleFiltered("elder")}
            className={
              catSelected === "elder"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            Elders
          </button>
          <button
            onClick={() => handleFiltered("patron")}
            className={
              catSelected === "patron"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            Patrons
          </button>
          <button
            onClick={() => handleFiltered("member")}
            className={
              catSelected === "member"
                ? "mx-1 px-2 py-1 rounded bg-dark text-white"
                : "mx-1 px-2 py-1 rounded bg-slate-200 border"
            }
          >
            Members
          </button>
        </div>
      </div>

      <div className="container p-3">
        <div className="row border bg-light p-2">
          <div className="col-1">No</div>
          <div className="col-9">Name</div>
          <div className="col-2">Action</div>
        </div>
        {filteredUsers.map((user, i) => {
          return (
            <div key={i} className="row border-bottom p-2">
              <div className="col-1">{++i}</div>
              <div className="col-9">
                <Link
                  to="/userprofile"
                  state={user.id}
                  className="text-decoration-none text-dark"
                >
                  <span className="text-dark">
                    {user.name} - ({user.role})
                  </span>
                </Link>
                <br />
                <i className="bi bi-envelope"></i>: {user.email}
                <br />
                <i className="bi bi-phone"></i>: {user.phone}
              </div>
              <div className="col-2">
                <a onClick={() => handleUpdate(user)}>
                  <i className="bi bi-pencil-square text-dark h4"></i>
                </a>{" "}
                <a onClick={() => handleDelete(user.id)}>
                  <i className="bi bi-trash text-danger h4"></i>
                </a>{" "}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableUser;
