import React, { useState, useContext } from "react";
import { Header, Footer } from "./";
import { useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { DashboardMenu, NavigationBar, AppLayout } from "../components";
import { SiteContext } from "../context";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  faqtitle: Yup.string()
    .label("Title")
    .required()
    .min(3, "Must have at least 3 characters"),
  faqstory: Yup.string().label("Content").required(),
  faqcat: Yup.string().label("Category").required(),
});

const FaqAdd = () => {
  let navigate = useNavigate();

  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("faqcat", values.faqcat);
    formData.append("faqtitle", values.faqtitle);
    formData.append("faqtitleslug", slugify(values.faqtitle));
    formData.append("faqstory", values.faqstory);
    formData.append("faqusername", userData.username);
    formData.append("role", userData.role);
    formData.append("action", "create");

    return fetch(`${apiUrl}/api/faqs.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/adminfaqs");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "FAQ submission failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar
                section={"Frequently Asked Questions"}
                goback={true}
              />

              <h3>FAQ Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  faqid: "",
                  faqcat: "",
                  faqtitle: "",
                  faqstory: "",
                  faqusername: userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <div>
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className="container my-2">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="faqcat">Category</label>
                          </div>
                          <Field
                            as="select"
                            id="faqcat"
                            name="faqcat"
                            value={values.faqcat}
                            placeholder="Category"
                            onBlur={handleBlur("faqcat")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            <option value="Services">Services</option>
                            <option value="General">General</option>
                          </Field>

                          {touched.faqcat && errors.faqcat && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.faqcat && errors.faqcat}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-2">
                          <div>
                            <label htmlFor="faqtitle">Question</label>
                          </div>
                          <Field
                            id="faqtitle"
                            name="faqtitle"
                            value={values.faqtitle}
                            placeholder="Question"
                            onBlur={handleBlur("faqtitle")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            className="form-control"
                          />
                          {touched.faqtitle && errors.faqtitle && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.faqtitle && errors.faqtitle}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="faqstory">Answer</label>
                          </div>
                          <Field
                            as="textarea"
                            id="faqstory"
                            name="faqstory"
                            value={values.faqstory}
                            placeholder="Answer"
                            onBlur={handleBlur("faqstory")}
                            autoCapitalize="none"
                            style={{
                              padding: 4,
                            }}
                            rows="3"
                            className="form-control"
                          />
                          {touched.faqstory && errors.faqstory && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.faqstory && errors.faqstory}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        type="submit"
                        onClick={handleSubmit}
                        title="Submit"
                        disabled={!isValid || isSubmitting}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(FaqAdd);
