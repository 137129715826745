import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { MotionWrap, classNames, TimeLeft } from "../components";
import moment from "moment";
import { SiteContext } from "../context";

const LeagueHome = () => {
  const { apiUrl, handleHomeMatches, handleHomeMatch, isMobile } =
    useContext(SiteContext);

  const [homeLeague, setHomeLeague] = useState([]);
  const [homeMatch, setHomeMatch] = useState([]);

  useEffect(() => {
    getLeagueData();
  }, []);

  const getLeagueData = () => {
    return fetch(`${apiUrl}/api/fetch_league_matches.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomeLeague(res.league_table);
          setHomeMatch(res.match_result);
        } else if (res.status === 400) {
          console.log(res.msg);
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div className="p-2">
      {homeLeague.length > 0 && (
        <div>
          <div className="bg-green-100 p-2">
            <h5 className="text-uppercase bg-green-300 p-2 font-semibold">
              BMG League 2023
            </h5>

            <div className="bg-slate-300 font-bold p-2 flex justify-between">
              <div className="w-[80px]">Team </div>
              <div className="w-[30px]">GP </div>
              <div className="w-[30px]">W </div>
              <div className="w-[30px]">D </div>
              <div className="w-[30px]">L </div>
              <div className="w-[30px]">GF </div>
              <div className="w-[30px]">GA</div>
              <div className="w-[30px]">GD </div>
              <div className="w-[30px]">Pts </div>
            </div>

            {homeLeague.map((item, i) => {
              return (
                <div key={i} className="flex p-2 justify-between">
                  <div className="w-[80px]">{item.flt_team} </div>
                  <div className="w-[30px]">{item.flt_games_played} </div>
                  <div className="w-[30px]">{item.flt_games_win} </div>
                  <div className="w-[30px]">{item.flt_games_draw} </div>
                  <div className="w-[30px]">{item.flt_games_loss} </div>
                  <div className="w-[30px]">{item.flt_goal_for} </div>
                  <div className="w-[30px]">{item.flt_goal_against} </div>
                  <div className="w-[30px]">{item.flt_goal_diff} </div>
                  <div className="w-[30px]">{item.flt_points} </div>
                </div>
              );
            })}

            <p className="text-slate-500 small px-2">W=3,D=1,L=0</p>

            <div className="bg-black text-white">
              <div className="flex justify-between items-center p-2">
                <h5 className="text-slate-300">Last Match</h5>
                <Link to="/match-results" className="btn btn-success btn-sm">
                  See all results
                </Link>
              </div>

              {homeMatch.map((item, i) => {
                return (
                  <div key={i} className="text-white rounded py-2">
                    <div className="flex justify-around items-start">
                      <div>
                        {item.fmr_h_team} ({item.fmr_h_team_outcome})
                        <p className="text-capitalize text-xs">
                          {item.fmr_h_team_scorers !== "undefined" &&
                            item.fmr_h_team_scorers}
                        </p>
                      </div>
                      <div className="w-[40px] flex justify-around">
                        <div>{item.fmr_h_team_goal} </div>
                        <div>{item.fmr_a_team_goal} </div>
                      </div>
                      <div>
                        <div>
                          {item.fmr_a_team} ({item.fmr_a_team_outcome})
                          <p className="text-capitalize text-xs">
                            {item.fmr_a_team_scorers !== "undefined" &&
                              item.fmr_a_team_scorers}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="font-light p-2">
                      Date: {moment(item.fmr_date).format("Do MMM YYYY")} (
                      {item.fmr_time})
                      <p className="font-light">Venue: {item.fmr_venue}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="my-1 bg-black text-white text-center rounded">
            <h4 className="py-2">Countdown to the next BMG</h4>
            <TimeLeft dd={"2023-09-02"} color="#333333" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MotionWrap(LeagueHome, classNames);
