import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  SubCategoryNewsDisplay,
  BannerSide,
  BannerTop,
  AppLayout,
} from "../components";
import { Dropdown } from "react-bootstrap";
import Seo from "./Seo";

const Events = () => {
  const { userData, apiUrl, siteconfig, handleCurrentPage, isMobile } =
    useContext(SiteContext);

  const navigate = useNavigate();

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = () => {
    setLoading(true);

    fetch(`${apiUrl}/api/events.php?action=fetch_events`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.rows);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  return (
    <div>
      <Seo
        title={`Home | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}

      <div className="container py-3 my-1">
        <div className="row">
          <div className="col-md-7 p-2">
            {events.length > 0 &&
              events.slice(0, 1).map((item, i) => {
                return (
                  <Link key={i} to={`/event/${item.eventtitle_slug}`}>
                    <ImageDisplay
                      url={`${apiUrl}/${item.e_photo}`}
                      title={item.eventtitle}
                      showText
                      largeimg
                      category=""
                      height={isMobile ? 280 : 520}
                    />
                  </Link>
                );
              })}
          </div>
          <div className="col-md-5">
            <div className="row">
              {events.length > 0 &&
                events.slice(1, 5).map((item, i) => {
                  return (
                    <div className="col-md-6 col-6 p-1">
                      <Link to={`/event/${item.eventtitle_slug}`}>
                        <ImageDisplay
                          url={`${apiUrl}/${item.e_photo}`}
                          title={item.eventtitle}
                          showText
                          height={210}
                        />
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row my-1">
          <div className="col-md-9">
            <div className="h2 my-3 py-3 text-dark">
              <span style={{ color: "#ff0000" }} className="h1">
                |{" "}
              </span>{" "}
              {`More Events`}
            </div>
            {events.length > 0 && (
              <div className="row">
                {events.slice(5, 20).map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        isMobile ? `col-md-4 py-1 my-1` : `col-md-4 py-2 my-2`
                      }
                    >
                      <Link
                        key={i}
                        className="text-decoration-none text-dark"
                        to={`/event/${item.eventtitle_slug}`}
                      >
                        {!isMobile && (
                          <ImageDisplay
                            url={`${apiUrl}/${item.e_photo}`}
                            title={item.eventtitle}
                            category=""
                            height={210}
                          />
                        )}
                        <div
                          className={
                            isMobile ? "h5 py-2 border-bottom" : "h5 py-2"
                          }
                        >
                          {item.eventtitle}
                        </div>
                        {!isMobile && (
                          <div>{item.eventdesc.split(" ", 10).join(" ")}</div>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="col-md-3">
            <BannerSide />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(Events);
