import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Logo, BannerTop } from "../components";
import { icons } from "../constants";
import { SiteContext } from "../context";
import moment from "moment";

const Footer = () => {
  const { siteconfig, apiUrl, categories, handleCurrentPage } =
    useContext(SiteContext);

  const [itemSelected, setItemSelected] = useState({});
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <div className="text-dark" style={{ backgroundColor: "#eee" }}>
        <div className="container">
          <BannerTop />
          <div className="row p-2">
            <div className="col-md-5 my-1 d-flex justify-content-center align-content-center">
              <div className="w-100">
                <img
                  src={icons.logo}
                  width={110}
                  height={150}
                  className="rounded"
                  alt="logo"
                />
                <p className="my-2">{siteconfig.shopdescription}</p>
              </div>
            </div>
            <div className="col-md-2 my-1">
              <p className="h5">Links</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/news"
                  >
                    News
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/blog"
                  >
                    Blog
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/photos"
                  >
                    Photo Gallery
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/videos"
                  >
                    Video Gallery
                  </Link>
                </p>
                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/events"
                  >
                    Events
                  </Link>
                </p>

                <p>
                  <Link
                    className="text-dark text-decoration-none py-1"
                    to="/about"
                  >
                    About Us
                  </Link>
                </p>
              </div>
            </div>

            <div className="col-md-2 my-1">
              <p className="h5">Support</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />

              <div className="my-2">
                <p>
                  <Link
                    className="text-dark text-decoration-none py-2"
                    onClick={() => handleCurrentPage("contact")}
                    to="/contact"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
              {(siteconfig.slfacebook !== "" ||
                siteconfig.sltwitter !== "" ||
                siteconfig.slinstagram !== "" ||
                siteconfig.slyoutube !== "") && (
                <p className="h5 mt-5 my-1">Follow us on</p>
              )}
              {siteconfig.slfacebook != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slfacebook}
                    target="_blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </span>
              )}
              {siteconfig.sltwitter != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.sltwitter}
                    target="_blank"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                </span>
              )}
              {siteconfig.slinstagram != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slinstagram}
                    target="_blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </span>
              )}
              {siteconfig.slyoutube != "" && (
                <span className="p-1 my-1">
                  <a
                    className="py-1 rounded-circle h4"
                    href={siteconfig.slyoutube}
                    target="_blank"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </span>
              )}
            </div>

            <div className="col-md-3 my-1">
              <p className="h5">Contact</p>
              <div
                style={{ width: 30 }}
                className="border-bottom border-3 border-danger"
              />
              <div className="my-2">
                <span className="p-1">{siteconfig.shopaddress}</span>
                <br /> <br />
                <span className="p-1">
                  <i className="bi bi-phone"></i>: {siteconfig.shopphone}
                </span>
                <br />
                <br />
                <span className="p-1">
                  <i className="bi bi-envelope"></i>:{" "}
                  {siteconfig.shopemailaddress}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ backgroundColor: "#FF0000" }}
          className="py-1 text-white small"
        >
          <div className="container justify-content-start py-1">
            <p className="py-1 font-weight-bold text-center">
              Copyright{" "}
              <Link className="text-white text-decoration-none" to="/">
                {siteconfig.shopname}
              </Link>{" "}
              {moment().format("YYYY")} |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/privacy-policy"
              >
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link
                className="text-white text-decoration-none"
                to="/page/terms-of-service"
              >
                Terms of Service
              </Link>{" "}
            </p>
            <i
              className="bi bi-chevron-up bg-white text-black rounded text-xl p-2 cursor-pointer float-right"
              onClick={() => window.scrollTo(0, 0)}
            ></i>
            <p className="py-1 font-weight-bold text-center">
              Developed by{" "}
              <a
                className="text-white text-decoration-none"
                href="https://justwebservices.com"
                target={"_blank"}
              >
                Just Web Services
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
