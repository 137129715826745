import React from 'react';
import { Link } from 'react-router-dom';
import { AppLayout } from "../components";

const NothingMatch = () => {
    return (<div>
        <div className='container my-3 py-3'>
        <p className="my-1">
        Page not found!
        </p>
        <Link to='/'>Back to Home</Link>
        </div>
    </div>
    )
}

export default AppLayout(NothingMatch);