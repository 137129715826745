import React from "react";
import { BsPeople, BsCalendar, BsTrophy } from "react-icons/bs";
import { MotionWrap, classNames } from "./";

const NGStats = () => {
  return (
    <div
      className="w-full bg-blue-800 text-slate-400 rounded py-5 px-3 grayscale my-3"
      style={{ maxWidth: "1300px" }}
    >
      <div className="md:flex justify-center md:justify-around content-center">
        <div className="w-[250px] mx-auto  md:w-[200px] m-2 p-3 border rounded">
          <div className="flex justify-center items-center font-semibold text-2xl">
            <BsCalendar size={28} className="mr-1" /> Since
          </div>
          <h2 className="text-center font-bold">2007</h2>
        </div>
        <div className="w-[250px] mx-auto md:w-[200px] justify-center content-center p-3 border rounded">
          <div className="flex justify-center items-center font-semibold text-2xl">
            <BsTrophy size={28} className="mr-1" /> Trophies
          </div>
          <h1 className="text-center font-bold">13</h1>
        </div>
        <div className="w-[250px] mx-auto  md:w-[200px] m-2 p-3 border rounded">
          <div className="flex justify-center items-center font-semibold text-2xl">
            <BsPeople size={28} className="mr-1" /> Members
          </div>
          <h1 className="text-center font-bold">12,700</h1>
        </div>
      </div>
    </div>
  );
};

export default MotionWrap(NGStats, classNames);
