import React, { useState, useContext } from "react";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { FaBars, FaSearch, FaArrowLeft, FaHome } from "react-icons/fa";
import { motion } from "framer-motion";
import { Dropdown } from "react-bootstrap";
import { icons } from "../constants";
import { SearchBox } from "./";
import { useNavigate, Link, NavLink } from "react-router-dom";
import { SiteContext } from "../context/index";

const Navbar = () => {
  const { isLoggedOn, userData, apiUrl, handleSignOut } =
    useContext(SiteContext);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const doSignOut = () => {
    navigate("/");
    handleSignOut();
  };

  const handleToggle = () => {
    setToggle((currToggle) => !currToggle);
  };

  const handleSubmit = (search) => {
    if (search !== "") {
      navigate("/search", { state: search });
      setShowSearchBox(false);
    }
  };

  return (
    <nav
      className="relative w-full h-120 md:shadow-lg text-white mb-7"
      style={{ backgroundColor: "red" }}
    >
      <div className="mx-auto" style={{ maxWidth: "1300px" }}>
        <div className="flex h-[70px] md:h-[80px] justify-between items-center">
          <div className="md:shrink-0 font-bold text-center p-2">
            <Link to={"/"}>
              <img
                src={icons.logo}
                alt="logo"
                className="absolute top-3 left-100 z-20 h-24 w-30 md:h-30 w-35"
              />
            </Link>
          </div>
          <div className="hidden md:flex">
            <ul className="flex">
              {[
                "home",
                "news",
                "about",
                "events",
                "blog",
                "photos",
                "videos",
                "contact",
              ].map((item) => (
                <li className="px-2 uppercase p-text" key={`link-${item}`}>
                  <NavLink
                    to={
                      item === "home" ? "/" : item === "about" ? "" : `/${item}`
                    }
                    className={({ isActive }) =>
                      isActive
                        ? "border-b-4 border-white text-white text-decoration-none mb-1 pb-1"
                        : "text-white text-decoration-none"
                    }
                  >
                    {item === "home" ? (
                      <FaHome size={20} className="text-white" />
                    ) : item === "about" ? (
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="white"
                          id="dropdown-basic"
                          className="text-white my-0 py-0 border-0"
                        >
                          ABOUT
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => navigate("/page/about-us")}
                          >
                            About Us
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate("/page/history")}
                          >
                            History
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate("/page/membership")}
                          >
                            Membership
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => navigate("/members")}>
                            Our People
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      item
                    )}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex justify-start items-center px-3">
            <div>
              {showSearchBox ? (
                <div className="mx-auto" style={{ maxWidth: "1300px" }}>
                  <div className="absolute top-[70px] right-0 md:pr-[100px] md:top-30 p-2 bg-black z-40 w-full flex justify-end items-center">
                    <FaArrowLeft
                      size={14}
                      className="h-8 w-8 rounded-full mr-1 text-white p-1 cursor-pointer"
                      onClick={() => setShowSearchBox(false)}
                    />
                    <SearchBox handleSubmit={handleSubmit} />
                  </div>
                </div>
              ) : (
                <FaSearch
                  size={24}
                  className="cursor-pointer"
                  onClick={() => setShowSearchBox(true)}
                />
              )}
            </div>
            <div className="px-1 mx-1">
              {isLoggedOn ? (
                <div className="px-1 py-0 my-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                      <span className="h-[25px], w-[25px]">
                        <img
                          src={
                            userData.photo.includes("images/profile")
                              ? `${apiUrl}/${userData.photo}`
                              : `${userData.photo}`
                          }
                          height={30}
                          width={30}
                          className="inline-block rounded-circle"
                          alt={userData.name}
                        />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <h6 className="border-bottom">Hello {userData.name}</h6>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/user")}>
                        Account
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => doSignOut()}>
                        <i className="bi bi-box-arrow-right h6"></i> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <div className="px-1 py-0 my-0">
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="bi bi-person text-success h4"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <h6 className="border-bottom">Hello Guest</h6>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/login")}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/signup")}>
                        Signup
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </div>
            <div className="flex md:hidden">
              <FaBars
                className="cursor-pointer"
                size={35}
                onClick={() => handleToggle()}
              />

              {toggle && (
                <div className="absolute top-0 left-0 bg-green-200 w-full h-screen  text-black p-4 z-50">
                  <motion.div
                    whileInView={{ x: [300, 0] }}
                    transition={{ duration: 0.85, ease: "easeOut" }}
                  >
                    <div className="flex justify-between content-center">
                      <div className="h-30 w-30">
                        <img
                          src={icons.logo}
                          alt="logo"
                          className="h-10 w-10"
                        />
                      </div>
                      <HiX
                        size={30}
                        className="cursor-pointer"
                        onClick={() => handleToggle()}
                      />
                    </div>
                    <div>
                      {[
                        "home",
                        "news",
                        "about",
                        "events",
                        "blog",
                        "photos",
                        "videos",
                        "contact",
                      ].map((item) => (
                        <Link
                          key={item}
                          to={
                            item === "home"
                              ? "/"
                              : item === "about"
                              ? ""
                              : `/${item}`
                          }
                          className="py-1 my-1 h6 uppercase border-bottom border-bottom-slate-300 text-decoration-none text-dark"
                          onClick={() =>
                            item === "about" ? "" : setToggle(false)
                          }
                        >
                          <div className="py-1 my-1 uppercase border-bottom border-bottom-slate-300">
                            {item === "about" ? (
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="green"
                                  id="dropdown-basic"
                                  className="text-black mx-0 px-0 my-0 py-0 border-0"
                                >
                                  ABOUT
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate("/page/about-us");
                                      setToggle(false);
                                    }}
                                  >
                                    About Us
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate("/page/history");
                                      setToggle(false);
                                    }}
                                  >
                                    History
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate("/page/membership");
                                      setToggle(false);
                                    }}
                                  >
                                    Membership
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate("/members");
                                      setToggle(false);
                                    }}
                                  >
                                    Our people
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            ) : (
                              item
                            )}
                          </div>
                        </Link>
                      ))}
                    </div>
                  </motion.div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
