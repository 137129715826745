import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  title: Yup.string().label("Title").required(),
});

const AdminElections = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [items, setItems] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getElections();
  }, []);

  useEffect(() => {
    if (userAction !== "create") {
      setStartDate(new Date(updateItem.eltimestart));
      setEndDate(new Date(updateItem.eltimeend));
    } else {
      setStartDate(new Date());
      setEndDate(new Date());
    }
  }, [userAction]);

  const getElections = () => {
    fetch(`${apiUrl}/api/elections.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("etype", values.electiontype);
    formData.append("ename", values.title);
    formData.append("estart", moment(startDate).format("YYYY-MM-DD"));
    formData.append("etimestartH", values.starttimeH);
    formData.append("etimestartM", values.starttimeM);
    formData.append("eend", moment(endDate).format("YYYY-MM-DD"));
    formData.append("etimeendH", values.endtimeH);
    formData.append("etimeendM", values.endtimeM);
    formData.append("estatus", values.status ? "Active" : "Inactive");
    formData.append("role", userData && userData.role);
    userAction === "update" && formData.append("id", values.id);
    formData.append("action", userAction);

    const sendType = "post";

    return fetch(`${apiUrl}/api/elections.php`, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getElections();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 30000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    setShowModal(true);
  };

  const handleDelete = (item) => {
    setItemSelected(item);
    setShowDeleteModal(true);
  };

  const doDelete = () => {
    let formData = new FormData();

    formData.append("id", itemSelected && itemSelected.electionid);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/elections.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          getElections();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const arrayrange = (start, end) => {
    let myArray = [];
    for (let i = start; i <= end; i++) {
      let formatted = i < 10 ? `0${i}` : `${i}`;
      myArray.push(formatted);
    }
    return myArray;
  };

  const arrayrangeMins = (start, end) => {
    let myArray = [];
    for (let i = start; i <= end; i += 5) {
      let formatted = i < 10 ? `0${i}` : `${i}`;
      myArray.push(formatted);
    }
    return myArray;
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doDelete()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "10%",
            right: isMobile ? 0 : "10%",
            width: isMobile ? "100%" : "80%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem ? updateItem.electionid : "",
              electiontype: updateItem ? updateItem.eltype : "",
              title: updateItem ? updateItem.elname : "",
              from: updateItem ? updateItem.eltimestart : "",
              to: updateItem ? updateItem.eltimeend : "",
              starttimeH:
                updateItem.time_startH !== "" ? updateItem.time_startH : "",
              starttimeM:
                updateItem.time_startM !== "" ? updateItem.time_startM : "",
              endtimeH: updateItem.time_endH !== "" ? updateItem.time_endH : "",
              endtimeM: updateItem.time_endM !== "" ? updateItem.time_endM : "",
              status: updateItem ? updateItem.elstatus === "Active" && 1 : 0,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="container my-2">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="electiontype">Type</label>
                      </div>
                      <Field
                        as="select"
                        id="electiontype"
                        name="electiontype"
                        value={values.electiontype}
                        onBlur={handleBlur("electiontype")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      >
                        <option value="">Select...</option>

                        <option value="Mock Election">Mock Election</option>
                        <option value="Election">Election</option>
                      </Field>
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="title">Name</label>
                      </div>
                      <Field
                        id="title"
                        name="title"
                        value={values.title}
                        placeholder="Name"
                        onBlur={handleBlur("title")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.title && errors.title && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.title && errors.title}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="startdate">Start date</label>
                          </div>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            className="border p-2"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <h6>Start time</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group my-1">
                              <div>
                                <label htmlFor="starttimeH">Hrs</label>
                              </div>
                              <Field
                                as="select"
                                id="starttimeH"
                                name="starttimeH"
                                value={values.starttimeH}
                                onChangeText={handleChange("starttimeH")}
                                placeholderTextColor="#999"
                                onBlur={handleBlur("starttimeH")}
                                autoCapitalize="none"
                                style={{
                                  padding: 4,
                                }}
                                className="form-control"
                              >
                                <option>H</option>
                                {arrayrange(0, 23).map((item, i) => {
                                  return <option key={i}>{item}</option>;
                                })}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group my-1">
                              <div>
                                <label htmlFor="starttimeM">Mins</label>
                              </div>
                              <Field
                                as="select"
                                id="starttimeM"
                                name="starttimeM"
                                value={values.starttimeM}
                                onChangeText={handleChange("starttimeM")}
                                placeholderTextColor="#999"
                                onBlur={handleBlur("starttimeM")}
                                autoCapitalize="none"
                                style={{
                                  padding: 4,
                                }}
                                className="form-control"
                              >
                                <option>M</option>
                                {arrayrangeMins(0, 55).map((item, i) => {
                                  return <option key={i}>{item}</option>;
                                })}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="enddate">End date</label>
                          </div>
                          <DatePicker
                            dateFormat={"yyyy-MM-dd"}
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                            }}
                            className="border p-2"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <h6>End time</h6>
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group my-1">
                              <div>
                                <label htmlFor="endtimeH">Hrs</label>
                              </div>
                              <Field
                                as="select"
                                id="endtimeH"
                                name="endtimeH"
                                value={values.endtimeH}
                                onChangeText={handleChange("endtimeH")}
                                placeholderTextColor="#999"
                                onBlur={handleBlur("endtimeH")}
                                autoCapitalize="none"
                                style={{
                                  padding: 4,
                                }}
                                className="form-control"
                              >
                                <option>H</option>
                                {arrayrange(0, 23).map((item, i) => {
                                  return <option key={i}>{item}</option>;
                                })}
                              </Field>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="form-group my-1">
                              <div>
                                <label htmlFor="endtimeM">Mins</label>
                              </div>
                              <Field
                                as="select"
                                id="endtimeM"
                                name="endtimeM"
                                value={values.endtimeM}
                                onChangeText={handleChange("endtimeM")}
                                placeholderTextColor="#999"
                                onBlur={handleBlur("endtimeM")}
                                autoCapitalize="none"
                                style={{
                                  padding: 4,
                                }}
                                className="form-control"
                              >
                                <option>M</option>
                                {arrayrangeMins(0, 45).map((item, i) => {
                                  return <option key={i}>{item}</option>;
                                })}
                              </Field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group my-1 py-2">
                      <div>
                        <label className="px-2" htmlFor="status">
                          Status
                        </label>
                        <Field type="checkbox" name="status" />
                        {values.status ? "Active" : "Inactive"}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title={userAction}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary capitalize"
                  >
                    {userAction}
                  </button>

                  <button
                    onClick={() => setShowModal(false)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Elections |{" "}
                <Link to="/adminelectioncandidates">Candidates</Link> |{" "}
                <Link to="/adminelectionvotes">Votes</Link>
              </h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-2">Election</div>
                    <div className="col-1">Type</div>
                    <div className="col-2">Start</div>
                    <div className="col-2">End</div>
                    <div className="col-2">Status</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-2">{item.elname}</div>
                        <div className="col-1">{item.eltype}</div>{" "}
                        <div className="col-2">
                          {item.eltimestart} <br />
                          {item.time_startH}:{item.time_startM}
                        </div>
                        <div className="col-2">
                          {item.eltimeend} <br />
                          {item.time_endH}:{item.time_endM}
                        </div>
                        <div className="col-2">{item.elstatus}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminElections);
