import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import moment from "moment";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { removeTags, getHeading } from "../utils";
import { SiteContext } from "../context";
import { Header, Footer, Seo } from "./";

const ElectionVote = () => {
  const navigate = useNavigate();

  let { id, name } = useParams();

  const { apiUrl, siteconfig } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [elections, setElections] = useState([]);
  const [votes, setVotes] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [itemSelected, setItemSelected] = useState({});
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [selectedElection, setSelectedElection] = useState({});
  const [votedFor, setVotedFor] = useState({});
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getElections();
    window.scrollTo(0, 0);
  }, [id, name]);

  useEffect(() => {
    getVotes();
  }, []);

  const getElections = () => {
    setSelectedElection({});

    fetch(`${apiUrl}/api/electioncandidates.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let filteredElection = res.elections.filter(
            (item) => item.electionid === id
          );
          let filtered = res.rows.filter(
            (item) =>
              item.electionid === id &&
              item.elcandposition.toLowerCase().replace(" ", "-") === name
          );

          setElections(res.elections);
          setCandidates(res.rows);
          setFilteredCandidates(filtered);
          setSelectedElection(filteredElection[0]);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getVotes = () => {
    let formData = new FormData();

    formData.append("elid", id);
    formData.append("elposition", name.replace("-", " "));
    formData.append("action", "fetchcat");

    fetch(`${apiUrl}/api/electionvotes.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setVotedFor({});

          let voted = res.rows.filter(
            (item) =>
              item.elvoteusername === userData.username &&
              item.elvoteposition.toLowerCase().replace(" ", "-") === name
          );
          setVotes(res.rows);
          voted.length > 0 && setVotedFor(voted[0]);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleVote = (item) => {
    setItemSelected(item);
    setShowModal(true);
  };

  const doVote = () => {
    let formData = new FormData();

    formData.append("elid", itemSelected && itemSelected.electionid);
    formData.append("evname", itemSelected && itemSelected.elcandidate);
    formData.append("evposition", itemSelected && itemSelected.elcandposition);
    formData.append("role", userData && userData.role);
    formData.append("evusername", userData && userData.username);
    formData.append("action", "create");

    fetch(`${apiUrl}/api/electionvotes.php`, {
      method: "post",
      body: formData,
    })
      .then((res) =>  res.json())
      .then((res) => {

        if (res.status === 200) {
          setShowModal(false);
          getVotes();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const calculateVotes = (vote) => {
    let percentage;

    if (parseInt(vote) === 0) {
      return `0 - (0%)`;
    }

    percentage = ((vote * 100) / votes.length).toFixed(2);

    return `${vote} - (${percentage}%)`;
  };

  const getVoting = () => {
    let thedate = `${selectedElection?.eltimeend} ${selectedElection?.time_endH}:${selectedElection?.time_endM}`;
    if (new Date() < new Date(thedate)) {
      return true;
    } else {
      return false;
    }
  };

  const getVotingBegin = () => {
    let thedate = `${selectedElection?.eltimestart} ${selectedElection?.time_startH}:${selectedElection?.time_startM}`;
    if (new Date() < new Date(thedate)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "15%",
            right: "15%",
            width: "70%",
            height: 300,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm vote</p>
          <p>You are voting for {itemSelected.elcandidate}</p>
          <p>Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doVote()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <Seo
        title={"Elections | Vote | " + siteconfig.shopname}
        description={"EXCO Elections"}
        url={`${apiUrl}/elections}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="container my-2 px-3">
        <button
          onClick={() => navigate(-1)}
          title="Back"
          className="bg-gray-600 text-white mx-2 px-2 py-1"
        >
          {`< Go back`}
        </button>
        <div className="row">
          <div className="col-md-8">
            {selectedElection?.hasOwnProperty("elname") && (
              <div>
                <h3 className="py-2 my-2">{selectedElection?.elname}</h3>
                <h6>
                  {`From: ${selectedElection?.time_startH}:${
                    selectedElection?.time_startM
                  }, ${moment(selectedElection?.eltimestart).format(
                    "Do MMM, YYYY"
                  )} `}
                  to
                  {` ${selectedElection?.time_endH}:${
                    selectedElection?.time_endM
                  }, ${moment(selectedElection?.eltimeend).format(
                    "Do MMM, YYYY"
                  )}`}{" "}
                  <span className="bg-gray-800 text-white rounded px-2 py-1">
                    {getVotingBegin()
                      ? "Yet to commence"
                      : getVoting()
                      ? "Ongoing"
                      : "Concluded"}
                  </span>
                </h6>
              </div>
            )}
            <h2 className="my-3 py-2">
              {name.toUpperCase().replace("-", " ")}
            </h2>

            {votedFor.hasOwnProperty("elvoteusername") && (
              <p className="bg-red-800 text-white px-2 py-1 rounded">
                You voted for {votedFor.elvotecandidate} at
                {moment(votedFor.createdAt).format(" h:ma on Do, MMM YYYY")}
              </p>
            )}

            {filteredCandidates.length > 0 &&
              filteredCandidates.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="flex justify-between items-center bg-gray-100 my-2 p-3 rounded"
                  >
                    <div className="flex">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={`${apiUrl}/${item.elcandphoto}`}
                        alt="photo"
                      />
                      <div className="ml-3">
                        <div className="h4">{item.elcandidate}</div>
                        <div>
                          {getVoting() &&
                            (!votedFor.hasOwnProperty("elvoteusername") && !getVotingBegin() ? (
                              userData && userData.member_type === "full" ? (
                                <button
                                  onClick={() => handleVote(item)}
                                  className="bg-green-700 text-white h5 px-3 py-2 rounded"
                                >
                                  Vote
                                </button>
                              ) : (
                                <button className="bg-green-700 text-white h5 px-3 py-2 rounded">
                                  Not financially up to date
                                </button>
                              )
                            ) : null)}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end">
                      {!getVoting() && (
                        <span className="mx-2 p-2">
                          {calculateVotes(item.elcandvotes)}
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            {!getVoting() && (
              <div className="flex bg-gray-200 h5 justify-between items-center p-3">
                <span>Total</span>
                <span className="mx-2 p-2">{votes.length} - 100%</span>
              </div>
            )}
          </div>
          <div className="col-md-4 mt-2">
            {/* <h4 className="bg-green-700 text-white rounded border-bottom p-2">
              Elections
            </h4>
            {elections.length > 0 &&
              elections.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="text-decoration-none border-bottom my-1"
                  >
                    <button
                      className="text-dark text-decoration-none"
                      onClick={() => handleElection(item.electionid)}
                    >
                      <div className="p-2">{item.elname}</div>
                    </button>
                  </div>
                );
              })} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(ElectionVote);
