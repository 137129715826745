import React, { useContext } from "react";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaYoutube } from "react-icons/fa";
import { SiteContext } from "../context";

const SocialIcons = () => {
  const { siteconfig } = useContext(SiteContext);

  return (
    <div class="h-full fixed left-1 p-2 grid gap-4 content-center ...">
      <div>
        <a
          className="text-decoration-none text-dark"
          href={siteconfig.slfacebook}
          target="_blank"
        >
          <FaFacebookF />
        </a>
      </div>
      <div>
        <a
          className="text-decoration-none text-dark"
          href={siteconfig.sltwitter}
          target="_blank"
        >
          <BsTwitter />
        </a>
      </div>
      <div>
        <a
          className="text-decoration-none text-dark"
          href={siteconfig.slinstagram}
          target="_blank"
        >
          <BsInstagram />
        </a>
      </div>
      <div>
        <a
          className="text-decoration-none text-dark"
          href={siteconfig.slyoutube}
          target="_blank"
        >
          <FaYoutube />
        </a>
      </div>
    </div>
  );
};

export default SocialIcons;
