import React, { useState, useContext } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .label("Username")
    .required()
    .min(4, "Must have at least 4 characters"),
  fullname: Yup.string()
    .label("Full name")
    .required()
    .min(5, "Must have at least 5 characters"),
  phone: Yup.string()
    .label("Mobile phone")
    .required()
    .min(11, "Must have at least 11 characters")
    .max(14, "Must have a maximum of 14 characters"),
  profile: Yup.string().label("Profile").required(),
  email: Yup.string()
    .label("Email")
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  password: Yup.string()
    .label("Password")
    .min(5, "Password must have at least 5 characters "),
  password2: Yup.string().oneOf(
    [Yup.ref("password")],
    "Confirm Password must match Password"
  ),
  agree: Yup.boolean().oneOf([true], "Please check the agreement"),
});

const UpdateSignupForm = ({ handleSubmit, updateItem, setShowModal }) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [uploadedPic, setUploadedPic] = useState(null);

  const userData = JSON.parse(localStorage.getItem("user"));

  const { apiUrl } = useContext(SiteContext);

  return (
    <div>
      <div className="container">
        {errorMsg !== "" ? (
          <span className="p-2 my-2 border border-danger text-danger">
            {errorMsg}
          </span>
        ) : null}

        <Formik
          initialValues={{
            id: updateItem.id ? updateItem.id : "",
            username: updateItem.username ? updateItem.username : "",
            fullname: updateItem.name ? updateItem.name : "",
            phone: updateItem.phone ? updateItem.phone : "",
            photo: updateItem.photo ? updateItem.photo : "",
            membertype: updateItem.member_type ? updateItem.member_type : "",
            memberrole: updateItem.member_role ? updateItem.member_role : "",
            memberposition: updateItem.member_position
              ? updateItem.member_position
              : "",
            memberrank: updateItem.member_rank ? updateItem.member_rank : 1,
            profile: updateItem.profile ? updateItem.profile : "",
            email: updateItem.email ? updateItem.email : "",
            residence: updateItem.location_country
              ? updateItem.location_country
              : "",
            password: "",
            password2: "",
            userrole: updateItem.role ? updateItem.role : "",
            isactive: updateItem.isactive == 1 ? true : false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            handleSubmit(values, { setSubmitting, resetForm, setShowModal });
          }}
        >
          {({
            handleChange,
            values,
            handleSubmit,
            errors,
            isValid,
            isSubmitting,
            handleBlur,
            touched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} encype="multipart/form-data">
              <div className="row my-2">
                <div className="col-md-6">
                  <div className="form-group my-2">
                    <div>
                      <label htmlFor="fullname">Full name</label>
                    </div>
                    <Field
                      id="fullname"
                      name="fullname"
                      value={values.fullname}
                      placeholder="Full name"
                      onBlur={handleBlur("fullname")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.fullname && errors.fullname && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.fullname && errors.fullname}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="email">Email</label>
                    </div>
                    <Field
                      id="email"
                      name="email"
                      value={values.email}
                      placeholder="Your email"
                      onBlur={handleBlur("email")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.email && errors.email && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="userrole">Role</label>
                    </div>
                    <Field
                      as="select"
                      id="userrole"
                      name="userrole"
                      value={values.userrole}
                      onBlur={handleBlur("userrole")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="admin">Admin</option>
                      <option value="member">Member</option>
                    </Field>

                    {touched.userrole && errors.userrole && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.userrole && errors.userrole}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="phone">Phone</label>
                    </div>
                    <Field
                      id="phone"
                      name="phone"
                      value={values.phone}
                      placeholder="Phone"
                      onBlur={handleBlur("phone")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.phone && errors.phone && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.phone && errors.phone}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="form-group my-2 py-2">
                    <div>
                      <label htmlFor="desc">Profile</label>
                    </div>
                    <Field
                      as="textarea"
                      id="profile"
                      name="profile"
                      value={values.profile}
                      placeholder="Your profile"
                      onBlur={handleBlur("Profile")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.profile && errors.profile && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.profile && errors.profile}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="residence">Location</label>
                    </div>
                    <Field
                      as="select"
                      id="residence"
                      name="residence"
                      value={values.residence}
                      placeholder="State of residence"
                      onBlur={handleBlur("residence")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="Nigeria">Nigeria</option>
                      <option value="North America">North America</option>
                      <option value="South America">South America</option>
                      <option value="Europe">Europe</option>
                      <option value="Asia">Asia</option>
                      <option value="Africa">Africa</option>
                    </Field>

                    {touched.residence && errors.residence && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.residence && errors.residence}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-3">
                    <div>
                      <label htmlFor="photo"> Upload picture</label>
                    </div>
                    <input
                      id="photo"
                      name="photo"
                      type="file"
                      onChange={(event) => {
                        setFieldValue("photo", event.currentTarget.files[0]);
                        setUploadedPic(event.currentTarget.files[0]);
                      }}
                    />
                    {uploadedPic && (
                      <img
                        className="my-2"
                        src={URL.createObjectURL(uploadedPic)}
                        class="img-fluid mt-2"
                        width={200}
                        height={100}
                        id="output_image"
                      />
                    )}
                    {updateItem.photo !== "" && (
                      <img
                        className="my-2"
                        src={`${apiUrl}/${updateItem.photo}`}
                        class="img-fluid mt-2"
                        width={200}
                        height={100}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="username">Username</label>
                    </div>
                    <Field
                      id="username"
                      name="username"
                      value={values.username}
                      placeholder="Username"
                      onBlur={handleBlur("username")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.username && errors.username && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.username && errors.username}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="password">Password</label>
                    </div>
                    <Field
                      type="password"
                      id="password"
                      name="password"
                      value={values.password}
                      placeholder="Password"
                      onBlur={handleBlur("password")}
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.password && errors.password && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password && errors.password}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="password2">Confirm Password</label>
                    </div>
                    <Field
                      type="password"
                      id="password2"
                      name="password2"
                      value={values.password2}
                      placeholder="Confirm Password"
                      onBlur={handleBlur("password2")}
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />

                    {touched.password2 && errors.password2 && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.password2 && errors.password2}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2">
                    <div className="p-2 h6">
                      <label htmlFor="membertype">Membership type</label>
                    </div>
                    <Field
                      as="select"
                      id="membertype"
                      name="membertype"
                      value={values.membertype}
                      onBlur={handleBlur("membertype")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="basic">Basic member</option>
                      <option value="associate">Associate member</option>
                      <option value="full">Full member</option>
                    </Field>
                  </div>

                  <div className="form-group my-2">
                    <div className="p-2 h6">
                      <label htmlFor="memberrole">Membership role</label>
                    </div>
                    <Field
                      as="select"
                      id="memberrole"
                      name="memberrole"
                      value={values.memberrole}
                      onBlur={handleBlur("memberrole")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    >
                      <option value="">Select...</option>
                      <option value="member">Member</option>
                      <option value="exco">EXCO</option>
                      <option value="bot">BOT</option>
                      <option value="elder">Elder</option>
                      <option value="patron">Patron</option>
                    </Field>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="memberposition">Member position</label>
                    </div>
                    <Field
                      id="memberposition"
                      name="memberposition"
                      value={values.memberposition}
                      placeholder="Member position"
                      onBlur={handleBlur("memberposition")}
                      autoCapitalize="none"
                      style={{
                        padding: 4,
                      }}
                      className="form-control"
                    />
                    {touched.memberposition && errors.memberposition && (
                      <div className="py-1">
                        <span className="text-danger">
                          {touched.memberposition && errors.memberposition}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="form-group my-2">
                    <div className="p-2 h6">
                      <label htmlFor="memberrank">Member rank</label>
                    </div>
                    <Field
                      as="select"
                      id="memberrank"
                      name="memberrank"
                      value={values.memberrank}
                      onBlur={handleBlur("memberrank")}
                      autoCapitalize="none"
                      style={{
                        padding: 10,
                      }}
                      className="form-control"
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                    </Field>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="agree"> Terms of service?</label>{" "}
                      {updateItem.isavailable ? "Yes" : "No"}
                    </div>
                  </div>

                  <div className="form-group my-1">
                    <div>
                      <label htmlFor="isactive"> Active?</label>{" "}
                      <Field type="checkbox" name="isactive" />{" "}
                      {values.isactive ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                buttonType="outline"
                onClick={handleSubmit}
                title="Update Account"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                className="btn btn-primary"
              >
                Update
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateSignupForm;
