import React, { useState, useEffect, useContext } from "react";
import { Header, Footer, ProjectGallery } from "./";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SiteContext } from "../context";
import {
  ImageDisplay,
  BannerTop,
  BannerSide,
  Comments,
  AppLayout,
  TwitterFeed,
  TopNews,
} from "../components";
import moment from "moment";
import { formatViews } from "../utils";
import Seo from "./Seo";
import { InlineShareButtons } from "sharethis-reactjs";

const EventDetail = () => {
  const {
    userData,
    apiUrl,
    siteconfig,
    homeEvents,
    isLoggedOn,
    categories,
    pages,
    handleCurrentPage,
    isMobile,
  } = useContext(SiteContext);

  const { title } = useParams();

  const navigate = useNavigate();

  const [animationPhotos, setAnimationPhotos] = useState([]);
  const [stories, setStories] = useState([]);
  const [event, setEvent] = useState({});
  const [storyArr, setStoryArr] = useState([]);
  const [story, setStory] = useState({});
  const [filteredOne, setFilteredOne] = useState([]);
  const [filteredFour, setFilteredFour] = useState([]);
  const [homeServices, setHomeServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getEventDetail();
    window.scrollTo(0, 0);
  }, [title]);

  const getEventDetail = () => {
    if (title === "" || title === undefined) {
      setErrorMsg("Error retrieving events");
      return;
    }
    setStory({});

    setLoading(true);

    fetch(`${apiUrl}/api/fetch_event_detail.php?action=fetch&slug=${title}`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          let newsArr = res.event[0].eventdesc.split("</p>");

          setEvent(res.event[0]);
          setStory(res.event[0]);
          setStoryArr(newsArr);
          setLoading(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setLoading(false);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const capitaliseText = (text) => {
    if (text === "") {
      return text;
    }
    let firstString = text.charAt(0);
    return firstString.toUpperCase() + "" + text.slice(1);
  };

  const doSanitize = (item) => {
    if (item === "" || item === undefined) {
      return item;
    }
    return item.replace(/<p>/g, "").replace(/<\/p>/g, " ");
  };

  const handleUpdate = (news) => {
    navigate("/updateevent", {
      state: { event: event, action: "update" },
    });
  };

  const getUrl = (url) => {
    if (isMobile) {
      let urlSplit = url.split("/");

      return `${apiUrl}/${urlSplit[0]}/${urlSplit[1]}/${urlSplit[2]}/${urlSplit[3]}/thumbnail/${urlSplit[4]}`;
    } else {
      return `${apiUrl}/${url}`;
    }
  };

  return (
    <div>
      <Seo
        title={
          event.hasOwnProperty("eventid")
            ? `${event.eventtitle} | ${siteconfig.shopname}`
            : `${siteconfig.shopname}`
        }
        description={event.hasOwnProperty("eventid") && event.eventdesc}
        url={`${apiUrl}/api/event_detail.php?id=${story.eventid}&title=${story.eventtitle_slug}`}
        img={
          event.hasOwnProperty("eventid") &&
          event.e_photo.includes("images/events")
            ? `${apiUrl}/${event.e_photo}`
            : event.e_photo
        }
      />

      {loading && (
        <div className="d-flex justify-content-center align-content-center my-5">
          <div class="spinner-border text-secondary" role="status" />
        </div>
      )}

      {errorMsg !== "" && <div className="container">{errorMsg}</div>}

      <div className="container py-3 my-1">
        <div className="row">
          <div className="container col-md-8 p-2">
            <h1 className="py-3 my-2">{event.eventtitle}</h1>

            {story.hasOwnProperty("eventid") && (
              <InlineShareButtons
                config={{
                  alignment: "center", // alignment of buttons (left, center, right)
                  color: "social", // set the color of buttons (social, white)
                  enabled: true, // show/hide buttons (true, false)
                  font_size: 16, // font size for the buttons
                  labels: "cta", // button labels (cta, counts, null)
                  language: "en", // which language to use (see LANGUAGES)
                  networks: [
                    // which networks to include (see SHARING NETWORKS)
                    "facebook",
                    "twitter",
                    "email",
                    "linkedin",
                    "whatsapp",
                    "messenger",
                  ],
                  padding: 12, // padding within buttons (INTEGER)
                  radius: 10, // the corner radius on each button (INTEGER)
                  show_total: false,
                  size: 40, // the size of each button (INTEGER)

                  // OPTIONAL PARAMETERS

                  url: `${apiUrl}/api/event_detail.php?id=${story.eventid}&title=${story.eventtitle_slug}`,
                  image: story.e_photo.includes("images/events")
                    ? `${apiUrl}/${story.e_photo}`
                    : story.e_photo, // (defaults to og:image or twitter:image)
                  description: doSanitize(story.eventdesc), // (defaults to og:description or twitter:description)
                  title: story.eventtitle, // (defaults to og:title or twitter:title)
                  message: doSanitize(story.eventdesc), // (only for email sharing)
                  subject: story.eventtitle, // (only for email sharing)
                  username: "ArsenalNigeriaConverge", // (only for twitter sharing)
                }}
              />
            )}

            <div className="small text-left py-3">
              <i className="bi bi-person-circle"></i> Admin |{" "}
              <i className="bi bi-clock"></i>:{" "}
              {moment(event.e_dateposted).format("Do MMM YYYY h:mma")}
            </div>

            <ImageDisplay
              url={`${apiUrl}/${event.e_photo}`}
              title={event.eventtitle}
              height={isMobile ? 300 : 400}
            />

            <div
              style={{ overflowX: "auto" }}
              className="py-2 container"
              dangerouslySetInnerHTML={{
                __html: event.eventdesc,
              }}
            />

            {userData && userData.role === "admin" && (
              <div className="my-2 py-1">
                <a
                  className="h4 border text-decoration-none p-1 rounded"
                  onClick={() => handleUpdate(event)}
                >
                  <i className="bi bi-pencil text-dark"></i> Edit
                </a>
              </div>
            )}

            <Comments slug={event.eventtitle} section="Events" />

            <div className="my-2">
              <p className="h3">You may also like...</p>
              <div className="row">
                {homeEvents
                  .filter((item) => item.eventtitle_slug !== title)
                  .sort((a, b) => 0.5 - Math.random())
                  .slice(0, 6)
                  .map((item, i) => {
                    return (
                      <div className="col-md-4 col-6" key={i}>
                        <Link
                          className="text-decoration-none text-dark"
                          to={`/event/${item.eventtitle_slug}`}
                        >
                          {item.e_photo !== undefined &&
                            item.e_photo !== "" &&
                            item.e_photo !== null && (
                              <ImageDisplay
                                url={`${apiUrl}/${item.e_photo}`}
                                title={item.eventtitle}
                                category=""
                                height={100}
                              />
                            )}
                          <div>{item.eventtitle}</div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="h2 text-dark">Events</div>
            {homeEvents
              .filter((item) => item.eventtitle_slug !== title)
              .slice(0, 15)
              .map((item, i) => {
                return (
                  <div
                    style={{ backgroundColor: "#ffffff" }}
                    key={i}
                    className="my-3 py-2 border-bottom"
                  >
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/event/${item.eventtitle_slug}`}
                    >
                      <div className="row">
                        <div className="col-3">
                          {item.e_photo !== undefined &&
                          item.e_photo !== "" &&
                          item.e_photo !== null ? (
                            <ImageDisplay
                              url={`${apiUrl}/${item.e_photo}`}
                              title={item.eventtitle}
                              height={isMobile ? 60 : 70}
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: isMobile ? 60 : 70,
                                backgroundColor: "#333",
                              }}
                            ></div>
                          )}
                        </div>
                        <div className="col-9 mx-0 px-0">
                          <p className="h6">{item.eventtitle}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

            <BannerSide />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(EventDetail);
