import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { TableUser, Pagination, NavigationBar, AppLayout } from "../components";
import { AddUserModal, UpdateUserModal } from "../modals";
import { DashboardMenu, SearchByPhone } from "../components";
import { SiteContext } from "../context";

const Customers = () => {
  const { apiUrl } = useContext(SiteContext);

  const [duplicates, setDuplicates] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [updateItem, setUpdateItem] = useState({});
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  useEffect(() => {
    getUsers();
  }, [page, limit]);

  const getUsers = () => {
    fetch(`${apiUrl}/api/users.php?action=fetch&page=${page}&limit=${limit}`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setUsers(res.rows);
          setFilteredUsers(res.rows);
          setTotal(res.rows.length);
        } else if (res.status === 400) {
          setMsg("No customers found");
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const doPrevious = async () => {
    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  };

  const doNext = async () => {
    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  };

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  };

  const filterUsers = (mrole) => {
    if (mrole === "all") {
      setFilteredUsers(users);
      setTotal(users.length);
    } else {
      let filtered = users.filter((item) => item.member_role === mrole);

      setFilteredUsers(filtered);
      setTotal(filtered.length);
    }
  };

  const checkUnique = (email, username, phone) => {
    setDuplicates([]);

    fetch(
      `${apiUrl}/api/users.php?action=checkunique&email=${email}&username=${username}&phone=${phone}`,
      {
        method: "get",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setDuplicates(res.msg);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal }) => {
    checkUnique(values.email, values.username, values.phone);

    if (duplicates.length > 0) {
      return false;
    }

    let formData = new FormData();

    let action = values.id === "" ? "create" : "update";

    formData.append("email", values.email);
    formData.append("username", values.username);
    formData.append("name", values.fullname);
    formData.append("phone", values.phone);
    formData.append("profile", values.profile);
    formData.append("password", values.password);
    formData.append("membertype", values.membertype);
    formData.append("memberrole", values.memberrole);
    formData.append("memberposition", values.memberposition);
    formData.append("memberrank", values.memberrank);
    formData.append(
      "memberid",
      `${values.membertype.charAt(0).toUpperCase()}${values.id}`
    );
    formData.append("photo", values.photo);
    formData.append("terms", values.agree ? 1 : 0);
    formData.append("residence", values.residence);
    formData.append("user", values.id);
    formData.append("role", "member");
    formData.append("isactive", values.isactive ? 1 : 0);
    formData.append("action", action);

    const url = `${apiUrl}/api/users.php`;
    const sendType = "post";

    return fetch(url, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setMsg(response.msg);
          setShowModal(false);
          resetForm({});
          getUsers();
          setSubmitting(false);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (response.status === 400) {
          setErrorMsg(response.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Signup failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setShowModal(true);
  };

  const handleUpdate = (user) => {
    setUpdateItem(user);
    setShowUpdateModal(true);
  };

  const doDelete = (id) => {
    fetch(`${apiUrl}/api/users.php?id=${id}&action=delete`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          getUsers();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <AddUserModal
        handleSubmit={handleSubmit}
        showModal={showModal}
        setShowModal={setShowModal}
        errorMsg={errorMsg}
      />
      <UpdateUserModal
        updateItem={updateItem}
        handleSubmit={handleSubmit}
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
        errorMsg={errorMsg}
      />
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>Users</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-between align-contents-center">
                <div className="col-8">
                  <SearchByPhone usertype="user" />
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New User
                  </button>
                </div>
              </div>

              <Pagination
                limit={limit}
                page={page}
                total={total}
                cangoBack={cangoBack}
                cangoForward={cangoForward}
                handleChange={handleChange}
                doNext={doNext}
                doPrevious={doPrevious}
              />

              {duplicates.length > 0 && (
                <p className="text-danger my-2 p-1 border border-danger">
                  {duplicates.map((item, i) => (
                    <div key={i}>{item}</div>
                  ))}
                </p>
              )}
              <TableUser
                users={users}
                filteredUsers={filteredUsers}
                filterUsers={filterUsers}
                onDelete={doDelete}
                handleUpdate={handleUpdate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(Customers);
