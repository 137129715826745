import React, { useContext, useState, useEffect } from "react";
import { Header, Footer } from "./";
import { Link } from "react-router-dom";
import { SiteContext } from "../context/index";
import { DashboardMenu, AppLayout } from "../components";

const UserDashboard = () => {
  const { apiUrl, msg } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <h2 className="my-2 p-3">
              Welcome {userData.username} to your {userData.role} dashboard
            </h2>

            {msg != "" ? <span className="my-2">{msg}</span> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(UserDashboard);
