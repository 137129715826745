import React, { useState, useEffect, useContext, useRef } from "react";
import { SiteContext } from "../context/index";
import { AppLayout, Spinner, MasonryLayout } from "../components";
import {
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";

const Members = () => {
  const { userData, apiUrl, homePhotoCat, isLoggedOn } =
    useContext(SiteContext);

  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [memberCat, setMemberCat] = useState([
    "patron",
    "bot",
    "elder",
    "exco",
    "member",
  ]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [showFullPicture, setShowFullPicture] = useState(false);
  const [catSelected, setCatSelected] = useState("");
  const [itemSelected, setItemSelected] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedCat, setSelectedCat] = useState("All");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getMembers();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);

  useEffect(() => {
    firstLoad && handleFirst();
  }, [firstLoad]);

  const getMembers = () => {
    return fetch(`${apiUrl}/api/users.php?action=fetchmembers`, {
      method: "get",
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          let filtered = res.rows.sort((a, b) => b.member_rank - a.member_rank);
          setMsg(res.msg);
          setMembers(filtered);
          setFirstLoad(true);
          setLoading(false);
          setTimeout(() => {
            setMsg("");
          }, 2000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setLoading(false);
        } else {
          alert(
            "Operation failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleFirst = () => {
    setCatSelected("exco");
    doSelected("exco");
  };

  const doSelected = (cat) => {
    setFilteredMembers([]);

    if (cat === "member") {
      setSelectedCat("");
      setFilteredMembers(members);
      setCurrentIndex(0);
    } else {
      let allmembers = members.slice();
      let filteredCat = allmembers.filter((item) => item.member_role === cat);

      setSelectedCat(cat);
      setFilteredMembers(filteredCat);
      setCurrentIndex(0);
    }
  };

  const handleNext = () => {
    if (currentIndex === filteredMembers.length - 1)
      setCurrentIndex(filteredMembers.length - 1);

    if (currentIndex < filteredMembers.length - 1) {
      setCurrentIndex((curr) => curr + 1);
    }
    handleItemSelected(currentIndex);
  };

  const handlePrev = () => {
    if (currentIndex === 0) setCurrentIndex(0);
    if (currentIndex > 0) {
      setCurrentIndex((curr) => curr - 1);
    }
    handleItemSelected(currentIndex);
  };

  const handleItemSelected = (id) => {
    let item = filteredMembers.filter((item, i) => i === id);
    setItemSelected(item[0]);
    setShowFullPicture(true);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={
        showFullPicture ? "relative bg-black bg-opacity-40" : "relative"
      }
    >
      <h2 className="text-3xl font-bold my-2">Our People</h2>

      {loading && <Spinner message="We are getting our members..." />}

      {!loading && memberCat.length < 1 && <div>There are no members</div>}

      <div className="p-2" style={{ maxWidth: "1300px" }}>
        {memberCat.length > 0 && (
          <div classname="py-3 my-2">
            {memberCat.map((item, i) => {
              return (
                <button
                  type="button"
                  key={i}
                  onClick={() => {
                    doSelected(item);
                    setCatSelected(item);
                  }}
                  className={
                    catSelected === item
                      ? "uppercase py-2 px-2 bg-black text-white text-left md-text-center rounded"
                      : "uppercase py-2 px-2  bg-white text-black text-left md-text-center rounded"
                  }
                >
                  {item !== "bot" && item !== "exco" ? `${item}s` : item}
                </button>
              );
            })}
          </div>
        )}

        <div classname="my-1 w-full" style={{ maxWidth: "1300px" }}>
          {!loading && memberCat.length > 0 && catSelected === "" && (
            <div className="p-5 text-2xl">
              Click on any of the categories to view the profiles
            </div>
          )}
          <MasonryLayout
            images={filteredMembers}
            handleItemSelected={handleItemSelected}
            apiUrl={apiUrl}
            type="members"
            role={selectedCat}
          />
        </div>
      </div>

      {showFullPicture && (
        <div
          ref={ref}
          className="fixed bg-slate-700 top-[100px] left-0 justify-center content-center w-full h-screen p-2 z-150"
        >
          <div className="relative flex justify-between items-center h-[40px] z-100 p-3">
            <div className="text-white uppercase h4">{catSelected}</div>
            <div className="flex justify-center items-center">
              <FaChevronCircleLeft
                onClick={() => handlePrev()}
                size={30}
                className="bg-white rounded-full cursor-pointer p-1"
              />
              <FaChevronCircleRight
                onClick={() => handleNext()}
                size={30}
                className="bg-white rounded-full cursor-pointer ml-4 p-1"
              />
            </div>
            <div className="">
              <HiXCircle
                size={30}
                onClick={() => setShowFullPicture(false)}
                className="font-bold cursor-pointer bg-white text-black rounded-full p-0"
              />
            </div>
          </div>
          <div className="relative w-full md:w-[700px] mx-auto p-3">
            <div className="md:flex-row justify-center items-center md:justify-between md:items-between">
              <div className="flex text-white text-center">
                <img
                  src={`${apiUrl}/${itemSelected.photo}`}
                  alt={itemSelected.name}
                  className="w-[100px] h-[100px] rounded-full"
                />
                <div className="text-center md:text-start">
                  <h6>{itemSelected.name}</h6>
                  <p className="pl-5">
                    {itemSelected.member_position}{" "}
                    <span className="pl-1 uppercase">
                      {itemSelected.member_role}
                    </span>
                  </p>
                </div>
              </div>
              <div
                className="flex justify-start items-start text-white p-2 text-medium rounded"
                dangerouslySetInnerHTML={{
                  __html: itemSelected?.profile?.replace(
                    /(\r\n|\r|\n)/g,
                    "<br />"
                  ),
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLayout(Members);
