import React, { useState, useContext, useEffect } from "react";
import { Header, Footer } from ".";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field } from "formik";
import { DashboardMenu, NavigationBar, AppLayout } from "../components";
import { SiteContext } from "../context";
import moment from "moment";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = Yup.object().shape({
  category: Yup.string().required(),
  title: Yup.string().label("Title").required(),
  venue: Yup.string().label("Venue").required(),
  status: Yup.string().label("Status").required(),
});

const AdminEventUpdate = () => {
  let { event } = useLocation().state;

  let navigate = useNavigate();

  const { apiUrl, allCats, allSubCats } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [story, setStory] = useState(event.eventdesc);
  const [startDate, setStartDate] = useState(new Date(event.e_date_start));
  const [endDate, setEndDate] = useState(new Date(event.e_date_end));
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    if (story === "" || story === undefined) {
      alert("Please provide the content for the news item.");
      setSubmitting(false);
      return;
    }

    let formData = new FormData();

    formData.append("category", values.category);
    formData.append("title", values.title);
    formData.append("title_slug", slugify(values.title));
    formData.append("desc", story);
    formData.append("venue", values.venue);
    formData.append("image", values.photo);
    formData.append("startdate", moment(startDate).format("YYYY-MM-DD"));
    formData.append("starttime", values.starttime);
    formData.append("enddate", moment(endDate).format("YYYY-MM-DD"));
    formData.append("endtime", values.endtime);
    formData.append("status", values.status ? "Published" : "Draft");
    formData.append("username", userData.username);
    formData.append("role", userData.role);
    formData.append("event", values.id);
    formData.append("action", "update");

    console.log(formData);

    return fetch(`${apiUrl}/api/events.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/adminevents");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Event update failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const slugify = (string) => {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "");
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Events"} goback={true} />

              <h3>Event Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <Formik
                initialValues={{
                  id: event.eventid !== "" ? event.eventid : "",
                  category: event.eventcat !== "" ? event.eventcat : "",
                  title: event.eventtitle !== "" ? event.eventtitle : "",
                  venue: event.e_place !== "" ? event.e_place : "",
                  starttime:
                    event.e_time_start !== "" ? event.e_time_start : "",
                  endtime: event.e_time_end !== "" ? event.e_time_end : "",
                  title: event.eventtitle !== "" ? event.eventtitle : "",
                  photo: event.e_photo !== "" ? event.e_photo : "",
                  status: event.e_status != 0 ? "Published" : "Draft",
                  username:
                    event.e_username !== ""
                      ? event.e_username
                      : userData.username,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <div>
                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                      <div className="row my-2">
                        <div className="col-md-7">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="category">Category</label>
                            </div>
                            <Field
                              as="select"
                              id="category"
                              name="category"
                              value={values.category}
                              onChangeText={handleChange("category")}
                              placeholder="Category"
                              placeholderTextColor="#999"
                              onBlur={() => {
                                handleBlur("category");
                              }}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="Party">Party</option>
                              <option value="Tournament">Tournament</option>
                              <option value="General">General</option>
                            </Field>

                            {touched.category && errors.category && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.category && errors.category}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2">
                            <div>
                              <label htmlFor="title">Title</label>
                            </div>
                            <Field
                              id="title"
                              name="title"
                              value={values.title}
                              onChangeText={handleChange("title")}
                              placeholder="Title"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("title")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.title && errors.title && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.title && errors.title}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group my-2 p-2">
                            <div>
                              <label htmlFor="desc">Description</label>
                            </div>
                            <ReactQuill
                              theme="snow"
                              value={story}
                              onChange={setStory}
                              style={{
                                height: 250,
                                marginBottom: 20,
                                paddingBottom: 20,
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group my-3">
                            <div>
                              <label htmlFor="photo"> Upload picture</label>
                            </div>
                            <input
                              id="photo"
                              name="photo"
                              type="file"
                              onChange={(event) => {
                                setFieldValue(
                                  "photo",
                                  event.currentTarget.files[0]
                                );
                                setUploadedPic(event.currentTarget.files[0]);
                              }}
                            />
                            {uploadedPic && (
                              <img
                                className="my-2"
                                src={URL.createObjectURL(uploadedPic)}
                                class="img-fluid mt-2"
                                width={200}
                                height={100}
                                id="output_image"
                              />
                            )}
                          </div>

                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="venue">Venue</label>
                            </div>
                            <Field
                              multiline
                              id="venue"
                              name="venue"
                              value={values.venue}
                              onChangeText={handleChange("venue")}
                              placeholder="Venue"
                              placeholderTextColor="#999"
                              onBlur={handleBlur("venue")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.venue && errors.venue && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.venue && errors.venue}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="row">
                            <div className="col-6">
                              <div className="form-group my-1">
                                <div>
                                  <label htmlFor="startdate">Start date</label>
                                </div>
                                <DatePicker
                                  dateFormat={"yyyy-MM-dd"}
                                  selected={startDate}
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="form-group my-1">
                                <div>
                                  <label htmlFor="starttime">Start time</label>
                                </div>
                                <Field
                                  id="starttime"
                                  name="starttime"
                                  value={values.starttime}
                                  onChangeText={handleChange("starttime")}
                                  placeholder="8.00am"
                                  placeholderTextColor="#999"
                                  onBlur={handleBlur("starttime")}
                                  autoCapitalize="none"
                                  style={{
                                    padding: 4,
                                  }}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6">
                              {" "}
                              <div className="form-group my-1">
                                <div>
                                  <label htmlFor="enddate">End date</label>
                                </div>
                                <DatePicker
                                  dateFormat={"yyyy-MM-dd"}
                                  selected={endDate}
                                  onChange={(date) => {
                                    setEndDate(date);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              {" "}
                              <div className="form-group my-1">
                                <div>
                                  <label htmlFor="endtime">End time</label>
                                </div>
                                <Field
                                  id="endtime"
                                  name="endtime"
                                  value={values.endtime}
                                  onChangeText={handleChange("endtime")}
                                  placeholder="3.00pm"
                                  placeholderTextColor="#999"
                                  onBlur={handleBlur("endtime")}
                                  autoCapitalize="none"
                                  style={{
                                    padding: 4,
                                  }}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group my-1 py-2">
                            <div>
                              <label className="px-2" htmlFor="status">
                                Status
                              </label>
                              <Field type="checkbox" name="status" />{" "}
                              {values.status ? "Published" : "Draft"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        buttonType="outline"
                        onClick={handleSubmit}
                        title="Submit"
                        disabled={!isValid || isSubmitting}
                        loading={isSubmitting}
                        className="btn btn-primary"
                      >
                        Submit
                      </button>

                      <button
                        onClick={() => navigate(-1)}
                        title="Cancel"
                        className="btn btn-secondary mx-2"
                      >
                        Cancel
                      </button>
                    </form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminEventUpdate);
