import React from "react";
import { Link } from "react-router-dom";
import { getHeading } from "../utils";

const NewsBox = ({ apiUrl, news, num }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="p-2">
        {news?.slice(0, 1).map((item, i) => {
          return (
            <Link
              key={i}
              to={`/${item.newsid}/${item.ntitle_slug}`}
              className="text-decoration-none text-black md:flex hover:grayscale cursor-pointer"
            >
              <div className="shrink-0">
                <img
                  src={`${apiUrl}/${item.nphoto}`}
                  alt={item.ntitle}
                  className="w-full h-[250px] md:w-[400px] md:h-[300px] object-cover"
                />
              </div>
              <div className="p-2">
                <div className="py-2 text-3xl font-bold">{item.ntitle}</div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getHeading(item?.nbrief, 20),
                  }}
                  className="py-3 hidden md:flex"
                />
              </div>
            </Link>
          );
        })}
      </div>
      <div className="p-1 flex">
        {news?.slice(1, num).map((item, i) => {
          return (
            <Link
              key={i}
              to={`/${item.newsid}/${item.ntitle_slug}`}
              className="text-decoration-none text-black md:flex hover:grayscale cursor-pointer"
            >
              <div className="grid grid-col-4 m-1 gap-2">
                <img src={`${apiUrl}/${item.nphoto}`} alt={item.ntitle} />
                <div className="">{item.ntitle}</div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default NewsBox;
