import React, { lazy, Suspense, useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { Home, NothingMatch, Activation } from "../screens";
import ErrorBoundary from "../ErrorBoundary";

import { SiteContext } from "../context";

const Blogs = lazy(() => import("../screens/Blogs"));
const Blog = lazy(() => import("../screens/Blog"));
const Videos = lazy(() => import("../screens/Videos"));
const Video = lazy(() => import("../screens/Video"));
const Login = lazy(() => import("../screens/Login"));
const Signup = lazy(() => import("../screens/Signup"));
const Profile = lazy(() => import("../screens/Profile"));
const MatchResults = lazy(() => import("../screens/MatchResults"));

const Users = lazy(() => import("../screens/Users"));
const UserDashboard = lazy(() => import("../screens/UserDashboard"));
const UserProfileDisplay = lazy(() => import("../screens/UserProfileDisplay"));
const UpdatePassword = lazy(() => import("../screens/UpdatePassword"));
const Contact = lazy(() => import("../screens/Contact"));
const About = lazy(() => import("../screens/About"));
const Abouts = lazy(() => import("../screens/Abouts"));
const Articles = lazy(() => import("../screens/Articles"));
const ArticleAdd = lazy(() => import("../screens/ArticleAdd"));
const ArticleUpdate = lazy(() => import("../screens/ArticleUpdate"));
const ArticleCategories = lazy(() => import("../screens/ArticleCategories"));
const BlogsByCategory = lazy(() => import("../screens/BlogsByCategory"));
const BlogsByYear = lazy(() => import("../screens/BlogsByYear"));
const SitePages = lazy(() => import("../screens/SitePages"));
const SitePageAdd = lazy(() => import("../screens/SitePageAdd"));
const SitePageUpdate = lazy(() => import("../screens/SitePageUpdate"));
const PhotoGallery = lazy(() => import("../screens/PhotoGallery"));
const AdminEvents = lazy(() => import("../screens/AdminEvents"));
const AdminEventAdd = lazy(() => import("../screens/AdminEventAdd"));
const AdminEventUpdate = lazy(() => import("../screens/AdminEventUpdate"));
const Events = lazy(() => import("../screens/Events"));
const EventDetail = lazy(() => import("../screens/EventDetail"));

const SiteConfigUpdate = lazy(() => import("../screens/SiteConfigUpdate"));
const SiteConfig = lazy(() => import("../screens/SiteConfig"));
const PageDetail = lazy(() => import("../screens/PageDetail"));
const AdminNewsletters = lazy(() => import("../screens/AdminNewsletters"));
const NewsletterAdd = lazy(() => import("../screens/NewsletterAdd"));
const NewsletterUpdate = lazy(() => import("../screens/NewsletterUpdate"));
const AdminPhotoGallery = lazy(() => import("../screens/AdminPhotoGallery"));
const PhotoGalleryAdd = lazy(() => import("../screens/PhotoGalleryAdd"));
const PhotoGalleryAddMultiple = lazy(() =>
  import("../screens/PhotoGalleryAddMultiple")
);
const PhotoGalleryUpdate = lazy(() => import("../screens/PhotoGalleryUpdate"));
const AdminPhotoGalleryCat = lazy(() =>
  import("../screens/AdminPhotoGalleryCat")
);
const PhotoGalleryCatAdd = lazy(() => import("../screens/PhotoGalleryCatAdd"));
const PhotoGalleryCatUpdate = lazy(() =>
  import("../screens/PhotoGalleryCatUpdate")
);
const NewsCategory = lazy(() => import("../screens/NewsCategory"));
const NewsSubCategory = lazy(() => import("../screens/NewsSubCategory"));
const NewsDetail = lazy(() => import("../screens/NewsDetail"));
const AdminBannerAds = lazy(() => import("../screens/AdminBannerAds"));
const AdminBannerAdsUpdate = lazy(() =>
  import("../screens/AdminBannerAdsUpdate")
);
const AdminBannerAdsAdd = lazy(() => import("../screens/AdminBannerAdsAdd"));
const AdminComments = lazy(() => import("../screens/AdminComments"));
const AdminNews = lazy(() => import("../screens/AdminNews"));
const AdminNewsAdd = lazy(() => import("../screens/AdminNewsAdd"));
const AdminNewsUpdate = lazy(() => import("../screens/AdminNewsUpdate"));
const AdminNewsCategories = lazy(() =>
  import("../screens/AdminNewsCategories")
);
const AdminNewsSubCategories = lazy(() =>
  import("../screens/AdminNewsSubCategories")
);
const AdminVideoGallery = lazy(() => import("../screens/AdminVideoGallery"));
const AdminVideoGalleryCat = lazy(() =>
  import("../screens/AdminVideoGalleryCat")
);
const VideoGalleryAdd = lazy(() => import("../screens/VideoGalleryAdd"));
const VideoGalleryCatAdd = lazy(() => import("../screens/VideoGalleryCatAdd"));
const VideoGalleryCatUpdate = lazy(() =>
  import("../screens/VideoGalleryCatUpdate")
);
const VideoGalleryUpdate = lazy(() => import("../screens/VideoGalleryUpdate"));
const AdminLeagueTeams = lazy(() => import("../screens/AdminLeagueTeams"));
const AdminMatchResults = lazy(() => import("../screens/AdminMatchResults"));
const AdminLeagueTable = lazy(() => import("../screens/AdminLeagueTable"));

const Members = lazy(() => import("../screens/Members"));

const OrgExpenseAdd = lazy(() => import("../screens/OrgExpenseAdd"));
const OrgExpenses = lazy(() => import("../screens/OrgExpenses"));
const OrgExpenseUpdate = lazy(() => import("../screens/OrgExpenseUpdate"));
const ContributionAddUser = lazy(() =>
  import("../screens/ContributionAddUser")
);
const ContributionsUser = lazy(() => import("../screens/ContributionsUser"));
const ContributionUpdateUser = lazy(() =>
  import("../screens/ContributionUpdateUser")
);
const ContributionPaymentUpdate = lazy(() =>
  import("../screens/ContributionPaymentUpdate")
);
const ContributionsDashboardAll = lazy(() =>
  import("../screens/ContributionsDashboardAll")
);
const ContributionsDashboardSingle = lazy(() =>
  import("../screens/ContributionsDashboardSingle")
);
const ContributionsDashboardExpenses = lazy(() =>
  import("../screens/ContributionsDashboardExpenses")
);
const ContributionsDashboard = lazy(() =>
  import("../screens/ContributionsDashboard")
);

const AdminElections = lazy(() =>
  import("../screens/AdminElections")
);
const AdminElectionCandidates = lazy(() =>
  import("../screens/AdminElectionCandidates")
);
const AdminElectionVotes = lazy(() =>
  import("../screens/AdminElectionVotes")
);
const Elections = lazy(() =>
  import("../screens/Elections")
);
const ElectionVote = lazy(() =>
  import("../screens/ElectionVote")
);




const SiteRoutes = () => {
  const { isLoggedOn } = useContext(SiteContext);

  const loggedin = localStorage.getItem("loggedin");

  const userloggedOn = loggedin || isLoggedOn;

  const RequireAuth = ({ children }) => {
    if (!userloggedOn) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const AuthNav = () => {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/activation/:rkey/:email" element={<Activation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<Abouts />} />
        <Route path="/match-results" element={<MatchResults />} />
        <Route path="/about/:title" element={<About />} />
        <Route path="/news" element={<NewsCategory />} />
        <Route
          path="/sub-category/:id/:section/:sid/:title"
          element={<NewsSubCategory />}
        />
        <Route path="/:id/:title" element={<NewsDetail />} />
        <Route path="/event/:title" element={<EventDetail />} />
        <Route path="/events" element={<Events />} />
        <Route
          path="/adminevents"
          element={
            <RequireAuth>
              <AdminEvents />
            </RequireAuth>
          }
        />
        <Route
          path="/addevent"
          element={
            <RequireAuth>
              <AdminEventAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updateevent"
          element={
            <RequireAuth>
              <AdminEventUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnews"
          element={
            <RequireAuth>
              <AdminNews />
            </RequireAuth>
          }
        />
        <Route
          path="/addnews"
          element={
            <RequireAuth>
              <AdminNewsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenews"
          element={
            <RequireAuth>
              <AdminNewsUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/elections"
          element={
            <RequireAuth>
              <Elections />
            </RequireAuth>
          }
        />

        <Route
          path="/election/:id/:name"
          element={
            <RequireAuth>
              <ElectionVote />
            </RequireAuth>
          }
        />
        <Route
          path="/adminelections"
          element={
            <RequireAuth>
              <AdminElections />
            </RequireAuth>
          }
        />
        <Route
          path="/adminelectioncandidates"
          element={
            <RequireAuth>
              <AdminElectionCandidates />
            </RequireAuth>
          }
        />
        <Route
          path="/adminelectionvotes"
          element={
            <RequireAuth>
              <AdminElectionVotes />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewscategories"
          element={
            <RequireAuth>
              <AdminNewsCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewssubcategories"
          element={
            <RequireAuth>
              <AdminNewsSubCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/admincomments"
          element={
            <RequireAuth>
              <AdminComments />
            </RequireAuth>
          }
        />
        <Route
          path="/adminleagueteams"
          element={
            <RequireAuth>
              <AdminLeagueTeams />
            </RequireAuth>
          }
        />
        <Route
          path="/adminleaguetable"
          element={
            <RequireAuth>
              <AdminLeagueTable />
            </RequireAuth>
          }
        />
        <Route
          path="/adminmatchresults"
          element={
            <RequireAuth>
              <AdminMatchResults />
            </RequireAuth>
          }
        />
        <Route
          path="/addorgexpenseuser"
          element={
            <RequireAuth>
              <OrgExpenseAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updateorgexpenseuser"
          element={
            <RequireAuth>
              <OrgExpenseUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/orgexpensesuser"
          element={
            <RequireAuth>
              <OrgExpenses />
            </RequireAuth>
          }
        />
        <Route
          path="/addcontributionuser"
          element={
            <RequireAuth>
              <ContributionAddUser />
            </RequireAuth>
          }
        />
        <Route
          path="/updatecontributionuser"
          element={
            <RequireAuth>
              <ContributionUpdateUser />
            </RequireAuth>
          }
        />
        <Route
          path="/contributionsuser"
          element={
            <RequireAuth>
              <ContributionsUser />
            </RequireAuth>
          }
        />
        <Route
          path="/contributiondashboard"
          element={<ContributionsDashboard />}
        >
          <Route path="" element={<ContributionsDashboardAll />} />
          {/* <Route path=":slug" element={<ContributionsDashboardAll />} /> */}
          <Route path="single" element={<ContributionsDashboardSingle />} />
          <Route path="expenses" element={<ContributionsDashboardExpenses />} />
        </Route>
        <Route
          path="/paymentlist"
          element={
            <RequireAuth>
              <ContributionPaymentUpdate />
            </RequireAuth>
          }
        />
        <Route path="/members" element={<Members />} />
        <Route
          path="/addarticle"
          element={
            <RequireAuth>
              <ArticleAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatearticle"
          element={
            <RequireAuth>
              <ArticleUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/articles"
          element={
            <RequireAuth>
              <Articles />
            </RequireAuth>
          }
        />
        <Route
          path="/articlecategories"
          element={
            <RequireAuth>
              <ArticleCategories />
            </RequireAuth>
          }
        />
        <Route
          path="/adminbannerads"
          element={
            <RequireAuth>
              <AdminBannerAds />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradadd"
          element={
            <RequireAuth>
              <AdminBannerAdsAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/banneradupdate"
          element={
            <RequireAuth>
              <AdminBannerAdsUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/users"
          element={
            <RequireAuth>
              <Users />
            </RequireAuth>
          }
        />
        <Route path="/photos" element={<PhotoGallery />} />
        <Route
          path="/addpage"
          element={
            <RequireAuth>
              <SitePageAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatepage"
          element={
            <RequireAuth>
              <SitePageUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminpages"
          element={
            <RequireAuth>
              <SitePages />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/addmultiplephotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryAddMultiple />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallery"
          element={
            <RequireAuth>
              <PhotoGalleryUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallery"
          element={
            <RequireAuth>
              <AdminPhotoGallery />
            </RequireAuth>
          }
        />
        <Route
          path="/addphotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatephotogallerycat"
          element={
            <RequireAuth>
              <PhotoGalleryCatUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminphotogallerycat"
          element={
            <RequireAuth>
              <AdminPhotoGalleryCat />
            </RequireAuth>
          }
        />
        <Route
          path="/addvideogallery"
          element={
            <RequireAuth>
              <VideoGalleryAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatevideogallery"
          element={
            <RequireAuth>
              <VideoGalleryUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminvideogallery"
          element={
            <RequireAuth>
              <AdminVideoGallery />
            </RequireAuth>
          }
        />
        <Route
          path="/addvideogallerycat"
          element={
            <RequireAuth>
              <VideoGalleryCatAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatevideogallerycat"
          element={
            <RequireAuth>
              <VideoGalleryCatUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminvideogallerycat"
          element={
            <RequireAuth>
              <AdminVideoGalleryCat />
            </RequireAuth>
          }
        />
        <Route
          path="/addnewsletter"
          element={
            <RequireAuth>
              <NewsletterAdd />
            </RequireAuth>
          }
        />
        <Route
          path="/updatenewsletter"
          element={
            <RequireAuth>
              <NewsletterUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminnewsletters"
          element={
            <RequireAuth>
              <AdminNewsletters />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteupdate"
          element={
            <RequireAuth>
              <SiteConfigUpdate />
            </RequireAuth>
          }
        />
        <Route
          path="/adminsiteconfig"
          element={
            <RequireAuth>
              <SiteConfig />
            </RequireAuth>
          }
        />
        <Route path="/page/:id" element={<PageDetail />} />
        {/* <Route path='/blog' element={<BlogLists />}>
                    <Route path='' element={<Blogs />} />
                    <Route path='post/:id' element={<Blog />} />
                </Route> */}
        <Route path="/post-category/:id" element={<BlogsByCategory />} />
        <Route path="/post-year/:id" element={<BlogsByYear />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/post/:id" element={<Blog />} />
        <Route path="/videos" element={<Videos />} />
        <Route path="/video/:id/:title" element={<Video />} />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/changepassword"
          element={
            <RequireAuth>
              <UpdatePassword />
            </RequireAuth>
          }
        />
        <Route
          path="/userprofile"
          element={
            <RequireAuth>
              <UserProfileDisplay />
            </RequireAuth>
          }
        />
        <Route
          path="/user"
          element={
            <RequireAuth>
              <UserDashboard />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NothingMatch />} />
      </Routes>
    );
  };

  return (
    <Router>
      <ErrorBoundary>
        <Suspense
          fallback={
            <div className="d-flex justify-content-center align-content-center my-5">
              <div class="spinner-border text-secondary" role="status" />
            </div>
          }
        >
          <AuthNav />
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
};

export default SiteRoutes;
