import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  position: Yup.string().label("Position").required(),
  manifesto: Yup.string().label("Manifesto").required(),
});

const AdminElectionCandidates = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [items, setItems] = useState([]);
  const [elections, setElections] = useState([]);
  const [members, setMembers] = useState([]);
  const [memberSelected, setMemberSelected] = useState({});
  const [electionSelected, setElectionSelected] = useState({});
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getMembers();
    getCandidates();
  }, []);

  const getCandidates = () => {
    fetch(`${apiUrl}/api/electioncandidates.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
          setElections(res.elections);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMembers = () => {
    fetch(`${apiUrl}/api/users.php?action=fetchmembers`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMembers(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let filtered = members.filter((item) => item.name === values.candidate);

    let formData = new FormData();

    formData.append("election", electionSelected.electionid);
    formData.append(
      "ecname",
      electionSelected.eltype === "Election"
        ? values.candidate
        : values.candidateenter
    );
    formData.append("ecmanifesto", values.manifesto);
    formData.append("ecposition", values.position);
    formData.append("ecvotes", values.votes);
    formData.append(
      "ecphoto",
      filtered.length > 0 ? filtered[0].photo : "images/noimage.png"
    );
    formData.append("username", userData && userData.username);
    formData.append("role", userData && userData.role);
    formData.append("id", values.id);
    formData.append("action", userAction);

    const sendType = "post";

    return fetch(`${apiUrl}/api/electioncandidates.php`, {
      method: sendType,
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
            getCandidates();
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 30000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setUploadedPic(null);
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    handleMember(item.elcandidate);
    handleElection(item.electionid);
    setShowModal(true);
  };

  const handleDelete = (item) => {
    setItemSelected(item);
    setShowDeleteModal(true);
  };

  const doDelete = () => {
    let formData = new FormData();

    formData.append("id", itemSelected && itemSelected.elcandid);
    formData.append("role", userData && userData.role);
    formData.append("username", userData && userData.username);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/electioncandidates.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          getCandidates();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMember = (id) => {
    setMemberSelected({});
    let filtered = members.filter((item) => item.name === id);
    setMemberSelected(filtered[0]);
  };

  const handleElection = (id) => {
    setElectionSelected({});
    let filtered = elections.filter((item) => item.electionid === id);
    setElectionSelected(filtered[0]);
  };

  const getElection = (id) => {
    let filtered = elections.filter((item) => item.electionid === id);
    return filtered[0].elname;
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span className="btn btn-primary" onClick={() => doDelete()}>
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "10%",
            right: isMobile ? 0 : "10%",
            width: isMobile ? "100%" : "80%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem ? updateItem.elcandid : "",
              election: updateItem ? updateItem.electionid : "",
              candidate: updateItem ? updateItem.elcandidate : "",
              candidateenter: updateItem ? updateItem.elcandidate : "",
              position: updateItem ? updateItem.elcandposition : "",
              manifesto: updateItem ? updateItem.elcandmanifesto : "",
              votes: updateItem ? updateItem.elcandvotes : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="container my-2">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="election">Election</label>
                      </div>

                      {elections?.map((item, i) => {
                        return (
                          <button
                            key={i}
                            onClick={() => handleElection(item.electionid)}
                            value={item.electionid}
                            className={
                              electionSelected.electionid === item.electionid
                                ? "bg-green-800 text-white mx-1 px-2 py-1 rounded"
                                : "bg-gray-100 text-dark mx-1 px-2 py-1 rounded"
                            }
                          >
                            {item.elname}
                          </button>
                        );
                      })}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="candidate">Candidate</label>
                      </div>
                      {userAction === "create" ? (
                        electionSelected.eltype === "Election" ? (
                          <Field
                            as="select"
                            id="candidate"
                            name="candidate"
                            value={values.candidate}
                            onBlur={handleBlur("candidate")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          >
                            <option value="">Select...</option>
                            {members.map((item, i) => {
                              return (
                                <option key={i} value={item.name}>
                                  {item.name}
                                </option>
                              );
                            })}
                          </Field>
                        ) : (
                          <Field
                            id="candidateenter"
                            name="candidateenter"
                            value={values.candidateenter}
                            onBlur={handleBlur("candidateenter")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />
                        )
                      ) : (
                        <div>{updateItem.elcandidate} </div>
                      )}
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="position">Position</label>
                      </div>
                      <Field
                        as="select"
                        id="position"
                        name="position"
                        value={values.position}
                        onBlur={handleBlur("position")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      >
                        <option value="">Select...</option>
                        <option value="President">President</option>
                        <option value="Vice President">Vice President</option>
                        <option value="Secretary">Secretary</option>
                        <option value="Financial Secretary">
                          Financial Secretary
                        </option>
                        <option value="Treasurer">Treasurer</option>
                        <option value="PRO">PRO</option>
                        <option value="Director of Socials">
                          Director of Socials
                        </option>
                        <option value="Provost">Provost</option>
                        <option value="Legal Adviser">Legal Adviser</option>
                      </Field>
                    </div>

                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="manifesto">Manifesto</label>
                      </div>
                      <Field
                        multiline
                        id="manifesto"
                        name="manifesto"
                        value={values.manifesto}
                        placeholder="Manifesto"
                        onBlur={handleBlur("manifesto")}
                        autoCapitalize="none"
                        style={{
                          padding: 10,
                        }}
                        className="form-control"
                      />

                      {touched.manifesto && errors.manifesto && (
                        <div className="py-1">
                          <span className="text-danger">
                            {touched.manifesto && errors.manifesto}
                          </span>
                        </div>
                      )}
                    </div>

                    {userAction === "update" && (
                      <div className="form-group my-1">
                        <div>
                          <label htmlFor="votes">Votes</label>
                        </div>
                        <Field
                          disabled
                          id="votes"
                          name="votes"
                          value={values.votes}
                          onBlur={handleBlur("votes")}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        />

                        {touched.votes && errors.votes && (
                          <div className="py-1">
                            <span className="text-danger">
                              {touched.votes && errors.votes}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title={userAction}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary capitalize"
                  >
                    {userAction}
                  </button>

                  <button
                    onClick={() => setShowModal(false)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Candidates - <Link to="/adminelections">Elections</Link> |{" "}
                <Link to="/adminelectionvotes">Votes</Link>
              </h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-3">Candidate</div>
                    <div className="col-3">Position</div>
                    <div className="col-3">Election</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items?.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-3">{item.elcandidate}</div>
                        <div className="col-3">{item.elcandposition}</div>
                        <div className="col-3">
                          {getElection(item.electionid)}
                        </div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminElectionCandidates);
