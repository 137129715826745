import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field } from "formik";
import { DashboardMenu } from "../components";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  hteam: Yup.string().label("Home team").required(),
  hteam_outcome: Yup.string().label("Home team outcome").required(),
  ateam: Yup.string().label("Away team").required(),
  ateam_outcome: Yup.string().label("Away team outcome").required(),
  matchvenue: Yup.string().label("Venue").required(),
});

const AdminMatchResults = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [items, setItems] = useState([]);
  const [teams, setTeams] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState({});
  const [userAction, setUserAction] = useState("create");
  const [matchDate, setMatchDate] = useState(new Date());
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getMatches();
    getTeams();
  }, [items]);

  const getMatches = () => {
    fetch(`${apiUrl}/api/match_result_manage.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTeams = () => {
    fetch(`${apiUrl}/api/teams_manage.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setTeams(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // alert(JSON.stringify(values));
    // setSubmitting(false);
    // return;
    let formData = new FormData();

    formData.append("fmatchdate", moment(matchDate).format("YYYY-MM-DD"));
    formData.append("year", moment(matchDate).format("YYYY"));
    formData.append("fhome", values.hteam);
    formData.append("fhome_goal", values.hteam_goal);
    formData.append("fhome_scorers", values.hteam_scorer);
    formData.append("fhome_outcome", values.hteam_outcome);
    formData.append("faway", values.ateam);
    formData.append("faway_goal", values.ateam_goal);
    formData.append("faway_scorers", values.ateam_scorer);
    formData.append("faway_outcome", values.ateam_outcome);
    formData.append("fvenue", values.matchvenue);
    formData.append("ftime", values.matchtime);
    formData.append("fremarks", values.matchremark);
    formData.append("listing", values.id);
    formData.append("action", userAction);

    return fetch(`${apiUrl}/api/match_result_manage.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          getMatches();
          setSubmitting(false);
          setShowModal(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 3000);
          setSubmitting(false);
        } else {
          alert(
            "Failure",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleAdd = () => {
    setUserAction("create");
    setItemSelected({});
    setUpdateItem({});
    setMatchDate(new Date());
    setShowModal(true);
  };

  const handleUpdate = (item) => {
    setUserAction("update");
    setUpdateItem(item);
    setMatchDate(new Date(item.fmr_date));
    setShowModal(true);
  };

  const handleDelete = (eventid) => {
    setItemSelected(eventid);
    setShowDeleteModal(true);
  };

  const doDelete = (id) => {
    let formData = new FormData();

    formData.append("listing", id);
    formData.append("role", userData.role);
    formData.append("action", "delete");

    fetch(`${apiUrl}/api/match_result_manage.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setShowDeleteModal(false);
          getTeams();
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 5000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <ReactModal
        isOpen={showDeleteModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: "10%",
            bottom: "10%",
            left: "25%",
            right: "25%",
            width: "50%",
            height: 250,
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "20px",
            left: "20px",
            right: "20px",
            bottom: "20px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <p className="h3 my-1 py-1">Confirm deletion</p>
          <p className="my-1 py-2">Action cannot be reversed!</p>
          <span
            className="btn btn-primary"
            onClick={() => doDelete(itemSelected)}
          >
            Confirm
          </span>{" "}
          <span
            className="btn btn-secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </span>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showModal}
        ariaHideApp={false}
        style={{
          overlay: {
            flex: 1,
            justifyContent: "center",
            alignContent: "center",
            position: "fixed",
            top: isMobile ? "2%" : "10%",
            bottom: isMobile ? "2%" : "10%",
            left: isMobile ? 0 : "20%",
            right: isMobile ? 0 : "20%",
            width: isMobile ? "100%" : "60%",
            height: isMobile ? "96%" : "80%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
          },
          content: {
            position: "absolute",
            top: "10px",
            left: "10px",
            right: "10px",
            bottom: "10px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
        }}
      >
        <div>
          <div className="d-flex justify-content-end">
            <span
              className="btn btn-secondary my-2"
              onClick={() => setShowModal(false)}
            >
              <i className="bi bi-x-square h4 text-white"></i>
            </span>
          </div>
          {errorMsg !== "" ? (
            <div className="mt-2">
              <span className="p-2 my-2 border border-danger text-danger">
                {errorMsg}
              </span>
            </div>
          ) : null}
          <Formik
            initialValues={{
              id: updateItem ? updateItem.fmr_id : "",
              hteam: updateItem ? updateItem.fmr_h_team : "",
              hteam_goal: updateItem ? updateItem.fmr_h_team_goal : "",
              hteam_scorer: updateItem ? updateItem.fmr_h_team_scorers : "",
              hteam_outcome: updateItem ? updateItem.fmr_h_team_outcome : "",
              ateam: updateItem ? updateItem.fmr_a_team : "",
              ateam_goal: updateItem ? updateItem.fmr_a_team_goal : "",
              ateam_scorer: updateItem ? updateItem.fmr_a_team_scorers : "",
              ateam_outcome: updateItem ? updateItem.fmr_a_team_outcome : "",
              matchvenue: updateItem ? updateItem.fmr_venue : "",
              matchtime: updateItem ? updateItem.fmr_time : "",
              matchremark: updateItem ? updateItem.fmr_remarks : "",
              username: updateItem ? updateItem.flt_postedby : "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
            }}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              isValid,
              isSubmitting,
              handleBlur,
              touched,
              setFieldValue,
            }) => (
              <div>
                <form onSubmit={handleSubmit} encype="multipart/form-data">
                  <div className="container my-2">
                    <div className="form-group my-1">
                      <div>
                        <label htmlFor="matchdate">Match date</label>
                      </div>
                      <DatePicker
                        dateFormat={"yyyy-MM-dd"}
                        selected={matchDate}
                        onChange={(date) => {
                          setMatchDate(date);
                        }}
                        className="border p-2"
                      />
                    </div>

                    <div className="bg-cyan-100 p-2">
                      <h6>Home team</h6>
                      <div className="row">
                        <div className="col-7">
                          <div className="form-group my-1">
                            <Field
                              as="select"
                              id="hteam"
                              name="hteam"
                              value={values.hteam}
                              onBlur={handleBlur("hteam")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select team...</option>
                              {teams.length > 0 &&
                                teams.map((item, i) => {
                                  return (
                                    <option value={item.flt_team}>
                                      {item.flt_team}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group my-1">
                            <Field
                              as="select"
                              id="hteam_outcome"
                              name="hteam_outcome"
                              value={values.hteam_outcome}
                              onBlur={handleBlur("hteam_outcome")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select outcome...</option>
                              <option value="W">W</option>
                              <option value="D">D</option>
                              <option value="L">L</option>
                            </Field>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="hteam_goal">Goals</label>
                            </div>
                            <Field
                              as="select"
                              id="hteam_goal"
                              name="hteam_goal"
                              value={values.hteam_goal}
                              onChangeText={handleChange("hteam_goal")}
                              placeholderTextColor="#999"
                              onBlur={handleBlur("hteam_goal")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </Field>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="hteam_scorer">Scorers</label>
                            </div>
                            <Field
                              id="hteam_scorer"
                              name="hteam_scorer"
                              value={values.hteam_scorer}
                              onChangeText={handleChange("hteam_scorer")}
                              placeholderTextColor="#999"
                              onBlur={handleBlur("hteam_scorer")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.hteam_scorer && errors.hteam_scorer && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.hteam_scorer && errors.hteam_scorer}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bg-slate-200 p-2">
                      <h6>Away team</h6>
                      <div className="row">
                        <div className="col-7">
                          <div className="form-group my-1">
                            <Field
                              as="select"
                              id="ateam"
                              name="ateam"
                              value={values.ateam}
                              onBlur={handleBlur("ateam")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              {teams.length > 0 &&
                                teams.map((item, i) => {
                                  return (
                                    <option value={item.flt_team}>
                                      {item.flt_team}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group my-1">
                            <Field
                              as="select"
                              id="ateam_outcome"
                              name="ateam_outcome"
                              value={values.ateam_outcome}
                              onBlur={handleBlur("ateam_outcome")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select outcome...</option>
                              <option value="W">W</option>
                              <option value="D">D</option>
                              <option value="L">L</option>
                            </Field>
                          </div>
                        </div>
                        <div className="col-7">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="ateam_goal">Goals</label>
                            </div>
                            <Field
                              as="select"
                              id="ateam_goal"
                              name="ateam_goal"
                              value={values.ateam_goal}
                              onChangeText={handleChange("ateam_goal")}
                              placeholderTextColor="#999"
                              onBlur={handleBlur("ateam_goal")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            >
                              <option value="">Select...</option>
                              <option value="0">0</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </Field>
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="form-group my-1">
                            <div>
                              <label htmlFor="hteam_scorer">Scorers</label>
                            </div>
                            <Field
                              id="ateam_scorer"
                              name="ateam_scorer"
                              value={values.ateam_scorer}
                              onChangeText={handleChange("ateam_scorer")}
                              placeholderTextColor="#999"
                              onBlur={handleBlur("ateam_scorer")}
                              autoCapitalize="none"
                              style={{
                                padding: 10,
                              }}
                              className="form-control"
                            />
                            {touched.ateam_scorer && errors.ateam_scorer && (
                              <div className="py-1">
                                <span className="text-danger">
                                  {touched.ateam_scorer && errors.ateam_scorer}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-7">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="matchvenue">Venue</label>
                          </div>
                          <Field
                            id="matchvenue"
                            name="matchvenue"
                            value={values.matchvenue}
                            onChangeText={handleChange("matchvenue")}
                            placeholderTextColor="#999"
                            onBlur={handleBlur("matchvenue")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />
                          {touched.matchvenue && errors.matchvenue && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.matchvenue && errors.matchvenue}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-5">
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="matchtime">Time</label>
                          </div>
                          <Field
                            id="matchtime"
                            name="matchtime"
                            value={values.matchtime}
                            onChangeText={handleChange("matchtime")}
                            placeholderTextColor="#999"
                            onBlur={handleBlur("matchtime")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />
                          {touched.matchtime && errors.matchtime && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.matchtime && errors.matchtime}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="form-group my-1">
                          <div>
                            <label htmlFor="matchremark">Remark</label>
                          </div>
                          <Field
                            id="matchremark"
                            name="matchremark"
                            value={values.matchremark}
                            onChangeText={handleChange("matchremark")}
                            placeholderTextColor="#999"
                            onBlur={handleBlur("matchremark")}
                            autoCapitalize="none"
                            style={{
                              padding: 10,
                            }}
                            className="form-control"
                          />
                          {touched.matchremark && errors.matchremark && (
                            <div className="py-1">
                              <span className="text-danger">
                                {touched.matchremark && errors.matchremark}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    title={userAction}
                    disabled={!isValid || isSubmitting}
                    className="btn btn-primary capitalize"
                  >
                    {userAction}
                  </button>

                  <button
                    onClick={() => setShowModal(false)}
                    title="Cancel"
                    className="btn btn-secondary mx-2"
                  >
                    Cancel
                  </button>
                </form>
              </div>
            )}
          </Formik>
        </div>
      </ReactModal>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Match results | <Link to="/adminleagueteams">League teams</Link>{" "}
                | <Link to="/adminleaguetable">League table</Link>
              </h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleAdd()}
                  >
                    New match result
                  </button>
                </div>
              </div>
              {items.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-2">Date</div>
                    <div className="col-2">Home Team</div>
                    <div className="col-2">HTS</div>
                    <div className="col-2">ATS</div>
                    <div className="col-2">Away Team</div>
                    <div className="col-2">Action</div>
                  </div>
                  {items.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-2">
                          {moment(item.fmr_date).format("Do MMM YYYY")}
                          <p>{item.fmr_venue}</p>
                        </div>
                        <div className="col-2">{item.fmr_h_team}</div>
                        <div className="col-2">
                          {item.fmr_h_team_goal}
                          <p>
                            {item.fmr_h_team_scorers !== "undefined" &&
                              item.fmr_h_team_scorers}
                          </p>
                        </div>
                        <div className="col-2">
                          {item.fmr_a_team_goal}
                          <p>
                            {item.fmr_a_team_scorers !== "undefined" &&
                              item.fmr_a_team_scorers}
                          </p>
                        </div>
                        <div className="col-2">{item.fmr_a_team}</div>
                        <div className="col-2">
                          <a onClick={() => handleUpdate(item)}>
                            <i className="bi bi-pencil text-dark h4"></i>
                          </a>
                          <a onClick={() => handleDelete(item.fmr_id)}>
                            <i className="bi bi-trash text-danger h4"></i>
                          </a>{" "}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminMatchResults);
