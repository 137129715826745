import React, { useState, useEffect, useContext } from "react";
import {
  BsPerson,
  BsArrowLeftCircleFill,
  BsArrowRightCircleFill,
} from "react-icons/bs";
import { MotionWrap, classNames } from "./";
import { SiteContext } from "../context";
import moment from "moment";

const Testimonials = () => {
  const { userData, apiUrl, homeTestimonials } = useContext(SiteContext);

  const [data, setData] = useState(homeTestimonials);
  const [filteredData, setFilteredData] = useState([homeTestimonials]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemSelected, setItemSelected] = useState({});

  useEffect(() => {
    handleItemSelected();
  }, [currentIndex]);

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((curr) => curr - 1);
    }

    handleItemSelected();
  };

  const handleNext = () => {
    if (currentIndex === data.length - 1) {
      setCurrentIndex(data.length - 1);
    }

    if (currentIndex < data.length - 1) {
      setCurrentIndex((curr) => curr + 1);
    }

    handleItemSelected();
  };

  const handleItemSelected = () => {
    setItemSelected({});
    setItemSelected(data[currentIndex]);
  };

  return (
    <div
      className="w-full rounded bg-green-100 p-3 my-3"
      style={{ maxWidth: "1300px" }}
    >
      {itemSelected && (
        <div>
          {" "}
          <h3 className="my-2 py-2 bg-green-300 uppercase px-2">
            What people say about us
          </h3>
          <div className="flex flex-col justify-center items-center rounded px-2 md:px-5">
            <div className="flex flex-col md:flex-row justify-content items-center w-full md:w-[800px] mx-auto h-[300px] max-h-[400px] overflow-y-scroll">
              <div className="text-center p-3">
                <div className="w-20 h-20 rounded-full mb-2 bg-cyan-800 ">
                  {itemSelected.tphoto !== "" ? (
                    <img
                      src={`${apiUrl}/${itemSelected.tphoto}`}
                      alt={itemSelected.tcustomer}
                      height="100%"
                      width="100%"
                    />
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <BsPerson size={60} />
                    </div>
                  )}
                </div>
                <h5>{itemSelected.tcustomer}</h5>
                <h6>{itemSelected.trole}</h6>
              </div>
              <div className="p-2 rounded">
                <div className="flex flex-col md:px-5 md:py-2 justify-start text-lg items-start">
                  {itemSelected.tmessage}
                </div>
              </div>
            </div>
            <div className="h-[60px] flex justify-center items-center">
              <BsArrowLeftCircleFill
                size={32}
                onClick={() => handlePrev()}
                className="cursor-pointer"
              />
              <BsArrowRightCircleFill
                size={32}
                onClick={() => handleNext()}
                className="mx-2 cursor-pointer"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MotionWrap(Testimonials, classNames);
