import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { AppLayout } from '../components';
import moment from 'moment';
import { Link, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { SiteContext } from '../context';

const ServiceLists = () => {

  const { apiUrl, pages } = useContext(SiteContext);

  const [pageServices, setPageServices] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    getPageServices();
  }, [])


  const getPageServices = () => {
    const cpages = pages.filter((item) => item.cpagemenu === 'Services');
    setPageServices(cpages);
    // fetch(`${apiUrl}/api/pages.php?action=fetchpageservices`, {
    //   method: 'get'
    // }).then((res) => {
    //   return res.json()
    // }).then((res) => {
    //   if (res.status === 200) {
    //     if (res.rows.length > 0) {
    //       setPageServices(res.rows);
    //     }
    //   } else if (res.status === 400) {
    //     setMsg(res.msg)
    //     setTimeout(() => {
    //       setMsg('')
    //     }, 3000)
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // })
  }

  return (
    <div>
      <div className='container my-1 p-1'>

        <div className='row'>
          <div className='col-md-8'>
            <Outlet />
          </div>
          <div className='col-md-4 bg-light mt-2'>
            <h4 className='bg-dark text-white rounded border-bottom p-2'>Services</h4>
            {pageServices.length > 0 && pageServices.map((page, i) => {
              return (
                <div key={i} className='text-decoration-none border-bottom my-1'>
                  <Link className='text-dark text-decoration-none' to={`/our-services/page/${page.cpagelinkname}`} state={{ service: page }} >
                    <div className='p-2'>{page.cpagename}</div>
                  </Link>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  )
}

export default AppLayout(ServiceLists);