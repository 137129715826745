import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { Link, useParams, useLocation } from "react-router-dom";
import { AppLayout } from "../components";
import { SiteContext } from "../context";

const Activation = () => {
  let { rkey, email } = useParams();

  const { apiUrl } = useContext(SiteContext);

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (msg === "" && errorMsg === "") {
      verifyEmail();
    }
  }, []);

  const verifyEmail = () => {
    fetch(
      `${apiUrl}/api/users.php?action=activate&rkey=${rkey}&email=${email}`,
      {
        method: "get",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setMsg(res.msg);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="container">
        <h2 className="my-3">Email verification for {email}</h2>
        {msg != "" && (
          <div className="my-3">
            <h5>{msg}</h5>
            <p className="h6 mt-3">
              <Link to={"/login"} className="text-decoration-none">
                Continue
              </Link>
            </p>
          </div>
        )}

        {errorMsg != "" && (
          <div className="my-3">
            <h5>
              {errorMsg} - {email}
            </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLayout(Activation);
