import React, { useState, useEffect, useContext } from "react";
import { NavigationBar, AppLayout } from "../components";
import ReactModal from "react-modal";
import { portflioCategories } from "../constants";
import { Link, useNavigate } from "react-router-dom";
import { DashboardMenu } from "../components";
import moment from "moment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { SiteContext } from "../context";

const validationSchema = Yup.object().shape({
  title: Yup.string().label("Title").required(),
  about: Yup.string().label("Description").required(),
});

const AdminElectionVotes = () => {
  const navigate = useNavigate();

  const { apiUrl, slugify, isMobile } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [uploadedPic, setUploadedPic] = useState(null);
  const [items, setItems] = useState([]);
  const [votes, setVotes] = useState([]);
  const [filteredVotes, setFilteredVotes] = useState([]);
  const [memberSelected, setMemberSelected] = useState({});
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState(null);
  const [userAction, setUserAction] = useState("create");
  const [msg, setMsg] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    getVotes();
    getElections();
  }, []);

  const getVotes = () => {
    fetch(`${apiUrl}/api/electionvotes.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setVotes(res.rows);
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const getElections = () => {
    fetch(`${apiUrl}/api/elections.php?action=fetch`, {
      method: "get",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.status === 200) {
          setItems(res.rows);
        } else if (res.status === 400) {
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleElection = (type) => {
    setItemSelected({});
    let filtered = votes.filter((item) => item.electionid === type);

    if (filtered.length > 0) {
      setFilteredVotes(filtered);
      setMsg(null);
    } else {
      setFilteredVotes([]);
      setMsg("No votes found");
    }
    setItemSelected(type);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <h3>
                Votes - <Link to="/adminelections">Elections</Link> |{" "}
                <Link to="/adminelectioncandidates">Candidates</Link>
              </h3>{" "}
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              {items.length > 0 &&
                items.map((item, i) => {
                  return (
                    <button
                      onClick={() => handleElection(item.electionid)}
                      key={i}
                      className={
                        itemSelected !== item.electionid
                          ? "bg-gray-200 text-dark px-2 py-1 mx-2 rounded"
                          : "bg-gray-800 text-white px-2 py-1 rounded"
                      }
                    >
                      {item.elname}
                    </button>
                  );
                })}
              {filteredVotes.length > 0 && (
                <div className="container p-3">
                  <div className="row border p-1 bg-light">
                    <div className="col-1">No</div>
                    <div className="col-3">Position</div>
                    <div className="col-4">Candidate</div>
                    <div className="col-4">Voter</div>
                  </div>
                  {filteredVotes.map((item, i) => {
                    return (
                      <div key={i} className="row border-bottom py-1">
                        <div className="col-1">{++i}</div>
                        <div className="col-3">{item.elvotecandidate}</div>
                        <div className="col-4">{item.elvoteposition}</div>
                        <div className="col-4">
                          {item.elvoteusername} <br />
                          {moment(item.createdAt).format("Do, MMM YYYY")}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(AdminElectionVotes);
