import React, { useState, useEffect, useContext } from "react";
import { Pagination, NavigationBar, AppLayout } from "../components";
import moment from "moment";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SiteContext } from "../context";
import { Header, Footer, Seo } from "./";

const Portfolios = () => {
  let navigate = useNavigate();

  const { apiUrl, pages, siteconfig, userData } = useContext(SiteContext);

  const [contents, setContents] = useState([]);
  const [pageServices, setPageServices] = useState([]);
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    getPageServices();
    window.scrollTo(0, 0);
  }, []);

  const getPageServices = () => {
    const cpages = pages.filter((item) => item.cpagemenu === "Clients");
    setContents(cpages);
    setPageServices(cpages);
  };

  return (
    <div>
      <Seo
        title={`Portfolio | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}/portfolio`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="my-2 container">
        <NavigationBar section={"Portfolio"} goback={false} />

        <div className="row">
          <div className="col-md-8">
            {contents.length > 0 ? (
              <div className="row">
                {contents.map((content, i) => {
                  return (
                    <div className="p-4 text-left">
                      <Link
                        className="text-decoration-none text-dark text-left"
                        to={`/portfolio/${content.cpagelinkname}`}
                        state={{ service: content }}
                      >
                        <div className="row my-1 py-2 border rounded">
                          <div className="col-4">
                            <img
                              src={`${apiUrl}/${content.cpagephoto}`}
                              height={200}
                              width={"100%"}
                              alt={content.cpagename}
                            />
                          </div>
                          <div className="col-8 p-3">
                            <h4>{content.cpagename}</h4>
                            <div
                              className="py-2"
                              dangerouslySetInnerHTML={{
                                __html: content.cpagecontent
                                  .split(" ", 20)
                                  .join(" "),
                              }}
                            />
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="my-2">No content found</div>
            )}
          </div>
          <div className="col-md-4 bg-light mt-2">
            <h4 className="bg-dark text-white rounded border-bottom p-2">
              Portfolio
            </h4>
            {pageServices.length > 0 &&
              pageServices.map((page, i) => {
                return (
                  <div
                    key={i}
                    className="text-decoration-none border-bottom my-1"
                  >
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/portfolio/${page.cpagelinkname}`}
                    >
                      <div className="p-2">{page.cpagename}</div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(Portfolios);
