import React from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const TwitterBox = () => {
  return (
    <div>
      {" "}
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="Naijagunners1"
        options={{ height: 400 }}
      />
    </div>
  );
};

export default TwitterBox;
