import React from "react";
import { SocialIcons, NavBar, Footer } from "./";

const AppLayout = (Component) =>
  function HOC() {
    return (
      <div>
        <div>
          <NavBar />
        </div>
        <div className="flex mx-auto">
          <div className="hidden md:flex static w-[40px]">
            <SocialIcons />
          </div>
          <div className="w-full mx-auto p-3">
            <Component />
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  };

export default AppLayout;
