import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  ImageDisplay,
  MotionWrap,
  classNames,
  BannerSide,
} from "../components";
import { SiteContext } from "../context";

const EventsHome = () => {
  const { homeEvents, apiUrl, isMobile } = useContext(SiteContext);
  return (
    <div className="full" style={{ maxWidth: "1300px" }}>
      <div className="row my-1">
        <div className="col-md-9">
          <div className="container py-3 my-1">
            <div className="bg-green-200 text-black uppercase rounded h1 p-2">
              Events
            </div>
            <div className="row">
              <div className="col-md-6 p-2">
                {homeEvents?.length > 0 &&
                  homeEvents?.slice(0, 1).map((item, i) => {
                    return (
                      <Link key={i} to={`/event/${item.eventtitle_slug}`}>
                        <ImageDisplay
                          url={`${apiUrl}/${item.e_photo}`}
                          title={item.eventtitle}
                          showText
                          largeimg
                          category=""
                          height={isMobile ? 280 : 420}
                        />
                      </Link>
                    );
                  })}
              </div>
              <div className="col-md-6">
                <div className="row">
                  {homeEvents?.length > 0 &&
                    homeEvents?.slice(1, 5).map((item, i) => {
                      return (
                        <div className="col-md-6 col-6 p-1">
                          <Link to={`/event/${item.eventtitle_slug}`}>
                            <ImageDisplay
                              url={`${apiUrl}/${item.e_photo}`}
                              title={item.eventtitle}
                              showText
                              height={210}
                            />
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <BannerSide />
        </div>
      </div>
    </div>
  );
};

export default MotionWrap(EventsHome, classNames);
