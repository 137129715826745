import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { SiteContext } from "../context";
import { AppLayout } from "../components";
import { removeTags, getHeading } from "../utils";
import Seo from "./Seo";

const MatchResults = () => {
  const { userData, apiUrl, loading, siteconfig } = useContext(SiteContext);

  const navigate = useNavigate();

  const [homeMatch, setHomeMatch] = useState([]);
  const [homeMatches, setHomeMatches] = useState([]);

  useEffect(() => {
    getLeagueData();
  }, []);

  const getLeagueData = () => {
    return fetch(`${apiUrl}/api/fetch_league_matches.php`, {
      method: "get",
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          setHomeMatch(res.match_result);
          setHomeMatches(res.match_results);
        } else if (res.status === 400) {
          console.log(res.msg);
        } else {
          console.log(
            "Error connecting to the server please check you details and try again"
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const processText = (text) => {
    let thetext = removeTags(text);

    thetext = getHeading(thetext, 25);

    return thetext;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-content-center my-5">
        <div className="spinner-border text-secondary" role="status" />
      </div>
    );
  }

  return (
    <div>
      <Seo
        title={`BMG Results | ${siteconfig.shopname}`}
        description={siteconfig.shopdescription}
        url={`${apiUrl}`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />

      <div className="w-full py-3 my-1" style={{ maxWidth: "1300px" }}>
        <div className="row">
          <div className="col-md-8">
            <div className="py-2">
              <h5 className="text-uppercase bg-green-300 p-2 font-semibold">
                BMG League 2023
              </h5>

              <div className="bg-slate-300 font-bold py-2 flex justify-between">
                <div className="w-[150px]">Date </div>
                <div className="w-[100px]">HOME </div>
                <div className="w-[30px]"></div>
                <div className="w-[30px]"></div>
                <div className="w-[100px]">AWAY </div>
              </div>

              {homeMatches.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="flex text-sm py-1 odd:bg-slate-200 even:bg-slate-100 justify-between"
                  >
                    <div className="w-[80px]">
                      {moment(item.fmr_date).format("Do MMM")} ({item.fmr_time})
                    </div>
                    <div className="w-[110px]">
                      {item.fmr_h_team}
                      <p className="text-capitalize text-xs">
                        {item.fmr_h_team_scorers !== "undefined" &&
                          item.fmr_h_team_scorers}
                      </p>
                    </div>
                    <div className="w-[30px]">{item.fmr_h_team_goal} </div>
                    <div className="w-[30px]">{item.fmr_a_team_goal} </div>
                    <div className="w-[110px]">
                      {item.fmr_a_team}
                      <p className="text-capitalize text-xs">
                        {item.fmr_a_team_scorers !== "undefined" &&
                          item.fmr_a_team_scorers}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-4">
            <h5>Last Match</h5>
            {homeMatch.map((item, i) => {
              return (
                <div key={i} className="bg-black text-white rounded py-2 px-1">
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      {item.fmr_h_team} ({item.fmr_h_team_outcome})
                      <p className="text-capitalize text-xs">
                        {item.fmr_h_team_scorers !== "undefined" &&
                          item.fmr_h_team_scorers}
                      </p>
                    </div>
                    <div className="w-[20px]">{item.fmr_h_team_goal} </div>
                    <div className="w-[20px]">{item.fmr_a_team_goal} </div>
                    <div>
                      {item.fmr_a_team} ({item.fmr_a_team_outcome})
                      <p className="text-capitalize text-xs">
                        {item.fmr_a_team_scorers !== "undefined" &&
                          item.fmr_a_team_scorers}
                      </p>
                    </div>
                  </div>

                  <div className="font-light p-1">
                    Date: {moment(item.fmr_date).format("Do MMM")} (
                    {item.fmr_time})
                    <p className="font-light">Venue: {item.fmr_venue}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(MatchResults);
