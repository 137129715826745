import React, { useState, useEffect, useContext } from "react";
import { Header, Footer } from "./";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Field } from "formik";
import { DashboardMenu, NavigationBar, AppLayout } from "../components";
import { SiteContext } from "../context";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const validationSchema = Yup.object().shape({
  status: Yup.string().label("Status").required(),
});

const TestimonialUpdate = () => {
  let testimonial = useLocation().state.testimonial;
  let navigate = useNavigate();

  const { apiUrl, slugify } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem("user"));

  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let formData = new FormData();

    formData.append("status", values.status);
    formData.append("testid", values.testid);
    formData.append("role", userData.role);
    formData.append("action", "update");

    return fetch(`${apiUrl}/api/testimonials.php`, {
      method: "post",
      body: formData,
    })
      .then((res) => res.json())
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setTimeout(() => {
            setMsg("");
          }, 3000);
          setTimeout(() => {
            navigate("/admintestimonials");
          }, 3000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            "Testimonial update failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-md-2 bg-light">
            <DashboardMenu />
          </div>
          <div className="col-md-10">
            <div className="container my-2">
              <NavigationBar section={"Testimonial"} goback={true} />

              <h3>Testimonial Manage</h3>
              <div className="my-2 d-flex justify-content-between">
                <div className="align-items-center">
                  {msg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {msg}
                      </span>
                    </div>
                  ) : null}
                  {errorMsg !== "" ? (
                    <div className="p-2">
                      <span className="p-2 border border-info text-dark">
                        {errorMsg}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="my-3">
                {testimonial.tphoto !== "" ? (
                  <img
                    className="my-2"
                    src={`${apiUrl}/${testimonial.tphoto}`}
                    class="img-fluid mt-2 rounded-circle"
                    width={100}
                    height={100}
                  />
                ) : (
                  <i className="bi bi-person h1"></i>
                )}
                <div>
                  {testimonial.tcustomer} - {testimonial.trole}
                </div>
              </div>

              <div className="my-1 w-50">
                <div className="h4">Testimonial</div>
                <div>{testimonial.tmessage}</div>
              </div>

              <Formik
                initialValues={{
                  testid: testimonial.tid != "" ? testimonial.tid : "",
                  status: testimonial.tstatus != "" ? testimonial.tstatus : "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  handleSubmit(values, { setSubmitting, resetForm });
                }}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  isValid,
                  isSubmitting,
                  handleBlur,
                  touched,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className="container my-2">
                      <div className="form-group my-2 py-2 w-25">
                        <div>
                          <label htmlFor="status">Status</label>
                        </div>
                        <Field
                          as="select"
                          id="status"
                          name="status"
                          value={values.status}
                          onBlur={handleBlur("status")}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        >
                          <option value="">Select...</option>
                          <option value="0">New</option>
                          <option value="1">Approved</option>
                        </Field>

                        {touched.status && errors.status && (
                          <div className="py-1">
                            <span className="text-danger">
                              {touched.status && errors.status}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      className="btn btn-primary"
                    >
                      Update
                    </button>

                    <button
                      onClick={() => navigate(-1)}
                      title="Cancel"
                      className="btn btn-secondary mx-2"
                    >
                      Cancel
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppLayout(TestimonialUpdate);
