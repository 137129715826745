import React, { useState, useEffect, useContext, useRef } from "react";
import { SiteContext } from "../context/index";
import { AppLayout, Spinner, MasonryLayout } from "../components";
import {
  FaArrowRight,
  FaChevronCircleLeft,
  FaChevronCircleRight,
} from "react-icons/fa";
import { HiXCircle } from "react-icons/hi";

const Photos = () => {
  const { userData, apiUrl, homePhotoCat, isLoggedOn } =
    useContext(SiteContext);

  const ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [photos, setPhotos] = useState([]);
  const [photoCat, setPhotoCat] = useState([]);
  const [filteredPhotos, setFilteredPhotos] = useState([]);
  const [showFullPicture, setShowFullPicture] = useState(false);
  const [catSelected, setCatSelected] = useState("");
  const [itemSelected, setItemSelected] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);
  const [selectedCat, setSelectedCat] = useState("All");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAllPhotos();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);

  useEffect(() => {
    firstLoad && handleFirst();
  }, [firstLoad]);

  const handleFirst = () => {
    setCatSelected(photoCat[0].gallcatname);
    doSelected(photoCat[0].gallcatid);
  };

  const doSelected = (cat) => {
    setFilteredPhotos([]);

    if (cat === "all") {
      setFilteredPhotos(photos);
      setCurrentIndex(0);
    } else {
      let allphotos = photos.slice();
      let filteredCat = allphotos.filter(
        (item) => parseInt(item.gcatid) === parseInt(cat)
      );

      setSelectedCat(cat);
      setFilteredPhotos(filteredCat);
      setCurrentIndex(0);
    }
  };

  const getAllPhotos = () => {
    return fetch(`${apiUrl}/api/photogallery.php?action=fetch`, {
      method: "get",
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.status === 200) {
          const filtered = res.categories.sort(
            (a, b) => b.gallcatid - a.gallcatid
          );
          setMsg(res.msg);
          setPhotos(res.photos);
          setPhotoCat(filtered);
          setFirstLoad(true);
          setLoading(false);
          setTimeout(() => {
            setMsg("");
          }, 2000);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg("");
          }, 5000);
          setLoading(false);
        } else {
          alert(
            "Operation failed",
            "Error connection to the server please check your details and try again",
            [{ text: "Okay" }]
          );
        }
      })
      .catch((error) => {
        console.log("Api call error", error.message);
      });
  };

  const handleNext = () => {
    if (currentIndex === filteredPhotos.length - 1)
      setCurrentIndex(filteredPhotos.length - 1);

    if (currentIndex < filteredPhotos.length - 1) {
      setCurrentIndex((curr) => curr + 1);
    }
    handleItemSelected(currentIndex);
  };

  const handlePrev = () => {
    if (currentIndex === 0) setCurrentIndex(0);
    if (currentIndex > 0) {
      setCurrentIndex((curr) => curr - 1);
    }
    handleItemSelected(currentIndex);
  };

  const doPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(parseInt(currentIndex - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCurrentIndex(currentIndex);
      setCangoBack(false);
      setCangoForward(true);
    }
    handleItemSelected(currentIndex);
  };

  const doNext = () => {
    if (currentIndex < filteredPhotos.length - 1) {
      setCurrentIndex(parseInt(currentIndex + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCurrentIndex(currentIndex);
      setCangoForward(false);
      setCangoBack(true);
    }
    handleItemSelected(currentIndex);
  };

  const handleItemSelected = (id) => {
    let item = filteredPhotos.filter((item, i) => i === id);
    setItemSelected(item[0]);
    setShowFullPicture(true);
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={
        showFullPicture ? "relative bg-black bg-opacity-40" : "relative"
      }
    >
      <h2 className="text-3xl font-bold my-2">Photo Gallery</h2>

      {loading && <Spinner message="We are getting the images..." />}

      {!loading && photoCat.length < 1 && <div>There are no pictures</div>}

      <div className="flex flex-col md:flex-row" style={{ maxWidth: "1300px" }}>
        {photoCat.length > 0 && (
          <div classname="w-full max-w-[300px]">
            <h2 className="mb-3 text-black font-bold text-2xl">Albums</h2>

            {photoCat.map((item, i) => {
              return (
                <div className="shadow-lg">
                  <button
                    type="button"
                    key={i}
                    onClick={() => {
                      doSelected(item.gallcatid);
                      setCatSelected(item.gallcatname);
                    }}
                    className={
                      catSelected === item.gallcatname
                        ? "w-full py-1 px-2 m-1 bg-black text-white text-left md-text-center rounded"
                        : "w-full py-1 px-2 m-1  bg-white text-black text-left md-text-center rounded"
                    }
                  >
                    <div className="flex justify-between items-center">
                      <div>{item.gallcatname} </div>
                      <div className="md:hidden">
                        <FaArrowRight size={24} />
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        )}

        <div classname="my-1 w-full" style={{ maxWidth: "1300px" }}>
          {catSelected !== "" && (
            <h1 className="font-bolder text-3xl p-2 my-2 bg-green-700 text-white rounded">
              {catSelected}
            </h1>
          )}
          {!loading && photoCat.length > 0 && catSelected === "" && (
            <div className="p-5 text-2xl">
              Click on any of the albums to view the pictures
            </div>
          )}
          {filteredPhotos && (
            <MasonryLayout
              images={filteredPhotos}
              handleItemSelected={handleItemSelected}
              apiUrl={apiUrl}
              type="photos"
              role=""
            />
          )}
        </div>
      </div>

      {showFullPicture && (
        <div
          ref={ref}
          className="fixed bg-slate-700 top-[100px] left-0 justify-center content-center w-full h-auto md:h-[600px] w-full z-120"
        >
          <div className="relative flex justify-between items-center h-[40px] z-100 p-3">
            <div className="flex justify-center items-center">
              <FaChevronCircleLeft
                onClick={() => handlePrev()}
                size={30}
                className="bg-white rounded-full cursor-pointer p-1"
              />
              <FaChevronCircleRight
                onClick={() => handleNext()}
                size={30}
                className="bg-white rounded-full cursor-pointer ml-4 p-1"
              />
            </div>
            <div className="">
              <HiXCircle
                size={30}
                onClick={() => setShowFullPicture(false)}
                className="font-bold cursor-pointer bg-white text-black rounded-full p-0"
              />
            </div>
          </div>
          <div className="relative flex justify-center items-center">
            <img
              src={`${apiUrl}/${itemSelected.gphoto}`}
              alt={itemSelected.gname}
            />
            <div className="absolute bottom-0 bg-white p-2 text-medium rounded">
              {itemSelected.gdesc}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppLayout(Photos);
